/* eslint-disable no-unused-vars */
import axios from "axios";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    /**
     * 获取薪资核算列表
     */
    getSalaryAuditList(_, { page, size, keywords, month }) {
        let params = {
            page,
        };
        if (size) params.size = size;
        if (keywords) params.keywords = keywords;
        if (month) params.month = month;
        return axios.get("/web/salary/list", {
            params,
        });
    },

    /**
     * 薪资核算
     */
    confirmSalaryAudit(_, { id }) {
        return axios.get("/web/salary/confirm", {
            params: {
                id,
            },
        });
    },

    /**
     * 个税扣除
     */
    taxSalaryAudit(_, { id, is_deduct_tax, deduct_tax_type }) {
        return axios.get("/web/salary/tax", {
            params: {
                id,
                is_deduct_tax,
                deduct_tax_type,
            },
        });
    },

    /**
     * 删除薪资核算
     */
    delSalaryAudit(_, { id }) {
        return axios.get("/web/salary/del", {
            params: {
                id,
            },
        });
    },

    /**
     * 获取薪资核算表格详细
     */
    getSalaryDetail(_, { id }) {
        return axios.get("/web/salary/detail", {
            params: {
                id,
            },
        });
    },

    /**
     * 刷新/编辑拆分表格
     */
    postSalaryDetail(
        _,
        {
            id,
            punch_day,
            grant_1,
            grant_2,
            grant_other,
            deduct_1,
            deduct_2,
            deduct_3,
            deduct_4,
            deduct_other,
            tax,
            salary_header,
            salary_pre_header,
            salary_tpl_text,
            is_form,
        }
    ) {
        let params = {
            id,
            punch_day,
            grant_1,
            grant_2,
            grant_other,
            deduct_1,
            deduct_2,
            deduct_3,
            deduct_4,
            deduct_other,
            salary_header,
            salary_pre_header,
            salary_tpl_text,
            tax,
        };
        if (is_form) params.is_form = "submit";

        return axios.post("/web/salary/afresh", params, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    },

    /**
     * 获取工资列表头部
     */
    getSalaryAuditHeader() {
        return axios.get("/web/salary/tpl");
    },

    /**
     * 下载薪资核算导入模板
     */
    getSalaryAuditTemplate() {
        return axios.get("/web/config/download", {
            params: {
                name: "salary",
            },
            responseType: "blob",
        });
    },

    /**
     * 导入工资
     */
    postImportSalary(_, fileUrl) {
        return axios.post(
            "/web/salary/import",
            {
                fileUrl,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },
    // 编辑工资明细 
    postEditSalary(_, params) {
        return axios.post(
            "/web/salary/edit",
            params,
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 获取薪资拆分列表
     */
    getSalarySplitList(_, { page, size, search, month }) {
        let params = {
            page,
        };
        if (size) params.size = size;
        if (search) params.search = search;
        if (month) params.month = month;
        return axios.get("/web/salarySheetSplit/index", {
            params,
        });
    },

    /**
     * 获取拆分资薪规则列表
     */
    getSalarySplitRulesList(_, { page, size }) {
        let params = {
            page,
        };
        if (size) params.size = size;
        return axios.get("/web/salarySplit/index", {
            params,
        });
    },

    /**
     * 获取拆分资薪规则详细
     */
    getSalarySplitRule(_, { id }) {
        return axios.get("/web/salarySplit/post", {
            params: {
                id,
            },
        });
    },

    /**
     * 编辑拆分资薪规则
     */
    editSalarySpiltRule(
        _,
        { id, title, min, max, type, department_ids, staff_ids, is_default }
    ) {
        return axios.post(
            "/web/salarySplit/post",
            {
                id,
                title,
                min,
                max,
                type,
                department_ids,
                staff_ids,
                is_default,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 删除拆分规则
     */
    delSalarySplitRule(_, { id }) {
        return axios.get("/web/salarySplit/del", {
            params: {
                id,
            },
        });
    },

    /**
     * 获取拆分表格详细
     */
    getSalarySplitDetail(_, { id }) {
        return axios.get("/web/salarySheetSplit/detail", {
            params: {
                id,
            },
        });
    },

    /**
     * 刷新/编辑拆分表格
     */
    postSalarySplitDetail(
        _,
        {
            id,
            punch_day,
            grant_1,
            grant_2,
            grant_other,
            deduct_1,
            deduct_2,
            deduct_3,
            deduct_4,
            deduct_other,
            tax,
            salary_header,
            salary_pre_header,
            salary_tpl_text,
            is_form,
        }
    ) {
        let params = {
            id,
            punch_day,
            grant_1,
            grant_2,
            grant_other,
            deduct_1,
            deduct_2,
            deduct_3,
            deduct_4,
            deduct_other,
            salary_header,
            salary_pre_header,
            salary_tpl_text,
            tax,
        };
        if (is_form) params.is_form = "submit";

        return axios.post("/web/salarySheetSplit/afresh", params, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    },

    /**
     * 获取借支明细列表
     */
    getSalaryBorrowList(_, { page, size, keyword, month }) {
        let params = {
            page,
        };
        if (size) params.size = size;
        if (keyword) params.keywords = keyword;
        if (month) params.month = month;
        return axios.get("/web/salaryBorrow/index", {
            params,
        });
    },

    /**
     * 添加借支
     */
    addSalaryBorrow(_, { staff_id, borrow_amount, borrow_explain }) {
        return axios.post(
            "/web/salaryBorrow/post",
            {
                staff_id,
                borrow_amount,
                borrow_explain,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 确认归还
     */
    postSalaryBorrowBack(_, { id, back_amount }) {
        return axios.post(
            "/web/salaryBorrow/back",
            {
                id,
                back_amount,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 获取借支记录列表
     */
    getSalaryBorrowLogList(_, { page, size, keyword, month }) {
        let params = {
            page,
        };
        if (size) params.size = size;
        if (keyword) params.keywords = keyword;
        if (month) params.month = month;
        return axios.get("/web/salaryBorrow/borrowLog", {
            params,
        });
    },

    /**
     * 获取薪资发放列表
     */
    getStatisticalList(_, { page, size, keyword, month }) {
        let params = {
            page,
        };
        if (size) params.size = size;
        if (keyword) params.keywords = keyword;
        if (month) params.month = month;
        return axios.get("/web/salary/statisticalList", {
            params,
        });
    },

    /**
     * 发薪
     */
    getToPayment(_, { id }) {
        return axios.get("/web/salary/grant", {
            params: {
                id,
            },
        });
    },

    /**
     * 获取五险一金列表,提交五险一金信息
     */

    getinsurance() {
        return axios.get("/web/insurance/getList");
    },

    setinsurance(_, insurance) {
        return axios.post("/web/insurance/setInsurance", {
            insurance,
        });
    },

    /**
     * 月工资列表，详情
     */
    loadsalarylist(_, { page, limit }) {
        return axios.get("/web/salary/list", {
            params: {
                page,
                size: limit,
            },
        });
    },

    getsalarydetail(_, params) {
        return axios.get("/web/salary/detail", {
            params
        });
    },

    /**
     * 月考勤明细列表，详情
     */
    loadattendance(_, params) {

        return axios.get("/web/attendance/list", {
            params
        });
    },

    /**
     * 查看详情
     */
    getattendancedetail(_, { id, page, limit }) {
        return axios.get("/web/attendance/detail", {
            params: {
                id: id,
                page: page,
                size: limit,
            },
        });
    },
    /**
     * 清除id考勤记录
     */
    delDepartment(_, { id }) {
        return axios.get("/web/attendance/delete", {
            params: {
                id,
            },
        });
    },

    /**
     * 获取导出数据表
     */
    async getexportinfo(_, params) {
        console.log(params);
        let url = "";
        if (params.type == 0) {
            url = "https://hrv1-end.yx2.qushiyun.com/web/attendance/export?id=" + params.id;
        } else if (params.type == 1) {
            url = "https://hrv1-end.yx2.qushiyun.com/web/salary/export?id=" + params.id;
        } else if (params.type == 2) {
            url = "https://hrv1-end.yx2.qushiyun.com/web/salary/grantExport?id=" + params.id;
        } else {
            url = "https://hrv1-end.yx2.qushiyun.com/web/salary/summaryExport?id=" + params.id;
        }

        var xhr = new XMLHttpRequest(); //定义http请求对象
        xhr.open("GET", url, true);
        let token = await JSON.parse(localStorage.getItem("user")).token;
        xhr.setRequestHeader("Authorization", "Bearer " + token);
        xhr.send();
        xhr.responseType = "blob"; // 返回类型blob
        xhr.onload = function () {
            // 定义请求完成的处理函数，请求前也可以增加加载框/禁用下载按钮逻辑
            if (this.status === 200) {
                var blob = this.response;
                console.log(this.response, "导出");
                var reader = new FileReader();
                reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
                reader.onload = function (e) {
                    var a = document.createElement("a");
                    a.download = params.name; //自定义下载文件名称
                    a.href = e.target.result;
                    document.body.appendChild(a); // 修复firefox中无法触发click
                    a.click();
                };
            }
        };
    },

    /**
     * 导入考勤表
     */
    postattendance(_, fileUrl) {
        console.log(_, fileUrl, "impotrt");
        return axios.post(
            "/web/attendance/import",
            {
                fileUrl: fileUrl.link,
                uid: JSON.parse(localStorage.getItem("user")).uid,
                local_name: fileUrl.local_name,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 确认月工资、发放月工资
     */
    confirmsalary(_, id) {
        return axios.get("/web/salary/sure", {
            params: {
                id,
            },
        });
    },

    paywage(_, { name, id }) {
        return axios.get("/web/salary/grant", {
            params: {
                bank_name: name,
                id: id,
            },
        });
    },

    /**
     * 薪资发放记录/详情
     */
    loadgrantList(_, { page, limit }) {
        return axios.get("/web/salary/grantList", {
            params: {
                page,
                size: limit,
            },
        });
    },

    getgrantDetail(_, { id, page, size }) {
        return axios.get("/web/salary/grantDetail", {
            params: {
                id,
                page,
                size,
            },
        });
    },

    /**
     * 考勤审核/计薪
     */
    attendanceexamine(_, { id, status, remark }) {
        return axios.get("/web/attendance/audit", {
            params: {
                id,
                status,
                remark,
            },
        });
    },

    calculationSalary(_, { id }) {
        return axios.get("/web/attendance/calculationSalary", {
            params: {
                id,
            },
        });
    },

    /**
     * 个人单独计算五险一金
     */
    getsocialfund(_, { id }) {
        // return axios.get("/web/salary/socialFundExport",{
        //     params:{
        //         id
        //     },
        //     responseType: "blob"
        // })
        return axios.get("/web/salary/socialFundExport", {
            params: {
                id,
            },
            responseType: "blob",
        });
    },
    uploadsocialfund(_, { link, id }) {
        return axios.post(
            "/web/salary/calculateSocialFundByExcel",
            {
                fileUrl: link,
                id,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },
    /***
     * 计算员工薪资扣除
     */
    exportsubsidymoney(_, { id }) {
        return axios.get("/web/salary/subsidyExport", {
            params: {
                id,
            },
            responseType: "blob",
        });
    },
    importsubsidymoney(_, { link, id }) {
        return axios.post(
            "/web/salary/calculateSubsidyExcel ",
            {
                fileUrl: link,
                id,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
