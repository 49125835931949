/* eslint-disable no-unused-vars */
import axios from "axios";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    /**
     * 获取老板驾驶仓信息
     */
    getdepartmentList() {
        return axios.get("/web/cockpit/dashboard");
    },

    /**
     * 入职离职人数统计图表
     */
    getStaffDataChart(_, { start_month, end_month }) {
        return axios.get("/web/cockpit/staffData", {
            params: {
                start_month,
                end_month,
            },
        });
    },

    /**
     * 员工考勤统计图表
     */
    getPunchDataChart(_, { month }) {
        return axios.get("/web/cockpit/punchData", {
            params: {
                month,
            },
        });
    },

    /**
     * 人工成本统计图表
     */
    getSalaryDataChart(_, { month }) {
        return axios.get("/web/cockpit/salaryData", {
            params: {
                month,
            },
        });
    },

    /**
     * 合同签署统计图表
     */
    getContractDataChart(_, { month }) {
        return axios.get("/web/cockpit/contractData", {
            params: {
                month,
            },
        });
    },

    /**
     * 险种详情统计图表
     */
     getInsuranceDataChart(_, { year }) {
        return axios.get("/web/cockpit/insuranceData", {
            params: {
                year,
            },
        });
    },

    getInsuranceDetail(_,{type}){
        return axios.get("/web/cockpit/insuranceDetail", {
            params: {
                type,
            },
        });
    },

    getSalaryDetail(_,{type}){
        return axios.get("/web/cockpit/salaryDetail", {
            params: {
                type,
            },
        });
    },

    getContractDetail(_,{type}){
        return axios.get("/web/cockpit/contractDetail", {
            params: {
                type,
            },
        });
    },

    getPunchDetail(_,{type}){
        return axios.get("/web/cockpit/punchDetail", {
            params: {
                type,
            },
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
