<template>
  <div id="app">
    <component :is="layout">
      <router-view v-if="isRouterAlive" />
    </component>
  </div>
</template>

<script>
import axios from "axios";
import md5 from "js-md5";
import qs from "qs";

axios.defaults.baseURL = process.env.VUE_APP_BASE_API;

export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      default_layout: "default", //设置layout
      isRouterAlive: true,
      key: "&key=3569fccc351a941a59c5bc85a8b25e25",
      app_id: "zhaoWxapp",
    };
  },
  created() {
    axios.interceptors.request.use(
      (config) => {
        console.log(JSON.parse(JSON.stringify(config)));

        // token白名单
        let tokenWhitelist = ["/web/login"];
        // token;
        if (tokenWhitelist.findIndex((item) => item === config.url) === -1) {
          config.headers.common["Authorization"] =
            "Bearer " + this.$store.getters["users/token"];
          // if (config.method === "get") {
          //     if (!config.params) config.params = {};
          //     config.params.token = this.$store.getters[
          //         "users/token"
          //     ];
          // } else {
          //     config.data.token = this.$store.getters["users/token"];
          // }
        }

        // 加密白名单
        let signWhitelist = ["/web/login"];
        if (
          signWhitelist.findIndex((item) => item === config.url) === -1 &&
          config.url.match("no_sign=1") == null
        ) {
          if (config.method === "get") {
            if (!config.params) config.params = {};
            config.params.app_id = this.app_id; // appid
            config.params.timeStamp = Math.floor(new Date().getTime() / 1000); // 时间戳
            config.params.nonceStr = this.randomString(); // 随机32位字符串
            let sdic = Object.keys(config.params)
              .filter(
                (item) =>
                  Object.prototype.toString.call(config.params[item]) !==
                    "[object Object]" &&
                  !Array.isArray(config.params[item]) &&
                  !item.includes("[")
              )
              .sort(); // 键排序
            let ciphertext = md5(
              encodeURIComponent(
                sdic.map((item) => `${item}=${config.params[item]}`).join("&") +
                  this.key
              )
            ).toUpperCase();
            // console.log(ciphertext);
            config.params.sign = ciphertext;
          } else if (config.method === "post") {
            config.data.app_id = this.app_id; // appid
            config.data.timeStamp = Math.floor(new Date().getTime() / 1000); // 时间戳
            config.data.nonceStr = this.randomString(); // 随机32位字符串
            let sdic = Object.keys(config.data)
              .filter(
                (item) =>
                  Object.prototype.toString.call(config.data[item]) !==
                    "[object Object]" && !Array.isArray(config.data[item])
              )
              .sort();
            console.log(sdic);
            let ciphertext = md5(
              encodeURIComponent(
                sdic.map((item) => `${item}=${config.data[item]}`).join("&") +
                  this.key
              )
            ).toUpperCase();
            // console.log(ciphertext);
            config.data.sign = ciphertext;
            config.data = qs.stringify(config.data);
          }
        } else {
          console.log("放行", config.url);
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        console.log("respponse", response);
        // 返回401，清除token信息并跳转到登录页面
        if (response.data.code === 401) {
          this.$message.error("登录失效，请重新登录");
          localStorage.removeItem("token");
          this.$router.replace({
            path: "/login",
            //登录成功后跳入浏览的当前页面
            // query: {redirect: router.currentRoute.fullPath}
          });
          return Promise.reject(response.data);
        } else if (
          response.headers["content-type"] ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          return response;
        } else if (
          response.headers["content-type"] === "application/vnd.ms-excel"
        ) {
          return response;
        } else if (response.data.code !== 200) {
          if (response.data.code == 21) {
            this.$confirm(
              "要使用合同功能需要先进行实名认证，是否继续?",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            ).then(() => {
              window.open(response.data.data.real_auth_url, "_blank");
            });
          } else if (response.data.code == 22) {
            this.$confirm(
              "要使用此功能需要先授权自动签合同能力，是否继续?",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            ).then(() => {
              window.open(response.data.data.auto_sign_url, "_blank");
            });
          }
          return Promise.reject(response.data);
        }
        return response;
      },
      (error) => {
        if (error.response) {
          switch (error.response.status) {
            // 返回401，清除token信息并跳转到登录页面
            case 401:
              this.$message.error("登录失效，请重新登录");
              localStorage.removeItem("token");
              this.$router.replace({
                path: "/login",
                //登录成功后跳入浏览的当前页面
                // query: {redirect: router.currentRoute.fullPath}
              });
              break;
          }
          // 返回接口返回的错误信息
          return Promise.reject(error.response);
        }
      }
    );
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || this.default_layout) + "-layout";
    },
  },
  methods: {
    //通过声明reload方法，控制router-view的显示或隐藏，从而控制页面的再次加载
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },

    /**
     * 生成随机字符串
     */
    randomString(len = 32) {
      let $chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
      let maxPos = $chars.length;
      let pwd = "";
      for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
  },
  beforeUpdate() {
    // console.log("beforeUpdateApp");
    // 监听全局,是否有authority,没有就加一个,防止出现点击顶部分类,左侧没有内容显示的问题
    if (!JSON.parse(localStorage.getItem("authority"))) {
      localStorage.setItem("authority", JSON.stringify({}));
    }
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  min-width: 1500px;
  font-family: "Microsoft YaHei", 微软雅黑, "MicrosoftJhengHei", 华文细黑,
    STHeiti, MingLiu;
}
.el-table .warm-row {
  background: oldlace;
}
// 富文本
.ql-editor {
  height: 360px !important;
}

.w-e-toolbar {
  z-index: 1001 !important;
}

.w-e-text-container {
  z-index: 1000 !important;
}

.no_select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.el-divider {
  background-color: #f0f4f8 !important;
}

input[type="password"]::-ms-reveal {
  display: none;
}

.custom_button {
  width: 90px;
  height: 36px;
  background-color: #4db9d5;
  border-radius: 4px;
  font-size: 12px;
  color: #ffffff;
  line-height: 36px;
  text-align: center;
  cursor: pointer;

  &:not(.disable):active {
    filter: brightness(1.2);
  }

  &.plain {
    background-color: #ffffff;
    color: #4db9d5;
    border: 1px solid #4db9d5;

    &:active {
      background-color: rgba($color: #4db9d5, $alpha: 0.1);
    }
  }

  &.disable {
    cursor: not-allowed;
  }
}

.table_title {
  font-size: 16px;
  color: #1c2438;
  padding-left: 13px;
  position: relative;
  margin: 15px 0;

  &::before {
    content: "";
    width: 3px;
    height: 16px;
    background-color: #4db9d5;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

td .cell {
  overflow-x: auto !important;
  text-overflow: unset !important;
  white-space: nowrap !important;
}
</style>
