/* eslint-disable no-unused-vars */
import axios from "axios";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  /**
   * 获取平台服务分类
   */
  getPlatformServicesCate() {
    return axios.get("/web/demand/categoryList");
  },

  /**
   * 获取平台服务列表
   */
  getPlatformServicesList(_, { page, keywords, cate_id }) {
    let params = {
      page,
      "search[demand_category_id]": cate_id,
      type: 1,
    };
    if (keywords) params.keywords = keywords;

    return axios.get("/web/demand/list", { params });
  },

  /**
   * 获取需求列表
   */
  getDemandList(_, { page, keywords }) {
    let params = {
      page,
      type: 2,
    };
    if (keywords) params.keywords = keywords;

    return axios.get("/web/demand/list", { params });
  },

  /**
   * 修改需求状态
   */
  getDemandStatus(_, { id, status }) {
    return axios.get("/web/demand/list", {
      params: {
        id,
        status,
      },
    });
  },

  /**
   * 获取服务超市列表
   */
  getServiceSupermarketList(_, { page, keywords, cate_id }) {
    let params = {
      page,
      type: 3,
    };
    if (keywords) params.keywords = keywords;
    if (cate_id) params["search[demand_category_id]"] = cate_id;
    else params["search[is_recommend]"] = 1;

    return axios.get("/web/demand/list", { params });
  },

  /**
   * 申请服务
   */
  getServiceApply(_, { id }) {
    return axios.get("/web/demand/createOrder", {
      params: {
        id,
      },
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
