<template>
  <el-collapse
    v-if="menuList.length > 0"
    :value="menuList[menuList.length - 1][0]"
    accordion
  >
    <el-collapse-item
      :name="index1"
      v-for="(item, index1) in menuList.slice(0, -1)"
      :key="index1"
    >
      <template slot="title">
        <i v-if="item.icon" class="icon" :class="item.icon"></i>
        <el-image
          v-if="item.src"
          :src="item.src"
          fit="contain"
          style="width: 22px; height: 22px; margin-left: 30px"
        ></el-image>
        <div class="title">{{ item.title }}</div>
      </template>
      <div v-for="(child, index2) in item.children" :key="index2">
        <div
          class="item"
          :class="{
            active:
              menuList[menuList.length - 1][0] == index1 &&
              menuList[menuList.length - 1][1] == index2,
          }"
          v-if="child.auth"
          @click="$router.push({ name: child.name })"
        >
          {{ child.title }}
        </div>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      activeName: "1",
    };
  },
  created() {
    console.log("menuList", this.menuList);
  },
  computed: {
    ...mapGetters("menu", {
      menuList: "getMenu",
    }),
  },
};
</script>

<style lang="scss">
.el-collapse {
  border: none;
}

.el-collapse-item__wrap {
  border: none;
  background: transparent;
}

.el-collapse-item__header {
  padding: 10px 0;

  .icon {
    font-size: 20px;
    margin-left: 30px;
  }

  .title {
    font-size: 16px;
    color: #495060;
    margin-left: 20px;
    font-weight: bold;
  }

  .el-collapse-item__arrow {
    font-size: 20px;
  }
}

.el-collapse-item__content {
  padding-bottom: 0;

  .item {
    height: 76px;
    line-height: 76px;
    padding-left: 70px;
    font-size: 16px;
    color: #495060;
    cursor: pointer;

    &.active {
      border-left: 4px solid #2f308a;
      background-color: #eef0f2;
      color: #2f308a;
    }
  }
}
</style>

<style lang="scss" scoped></style>
