import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

// import store from "@/store";

Vue.use(VueRouter);

const baseRoutes = [{
	path: "/",
	name: "Home",
	component: Home,
	meta: {
		layout: "header-only",
		requestAuth: true,
		headerNavSeleted: "Home",
	},
},
{
	path: "/login",
	name: "Login",
	component: () => import("../views/user/Login.vue"),
	meta: {
		layout: "single",
	},
},
{
	path: "/register",
	name: "Register",
	component: () => import("../views/user/Register.vue"),
	meta: {
		layout: "single",
	},
},
{
	path: "/forgetPassword",
	name: "ForgetPassword",
	component: () => import("../views/user/ForgetPassword.vue"),
	meta: {
		layout: "single",
	},
},
{
	path: "/employee/reminderNotice/:id",
	name: "ReminderNotice",
	props: true,
	component: () => import("../views/employee/employeeRelations/ReminderNotice.vue"),
	meta: {
		layout: "single",
	},
},
{
	path: "/employee/EntryManagementDetail/:isSave/:id/:Induction?",
	name: "EntryManagementDetail",
	component: () =>
		import("../views/employee/employeeRelations/EntryManagementDetail.vue"),
	props: true,
	meta: {
		requestAuth: true,
		index: [1, 0],
	},
},
];

// 企业
const enterpriseRoutes = [{
	path: "/enterprise",
	name: "Enterprise",
	//重定向
	// redirect: "/enterprise/enterpriseInfomation/infomations",
	redirect: "/enterprise/nothing",
	meta: {
		headerNavSeleted: "Enterprise",
		// menu: ["enterprise_infomation"],
		menu: ["enterprise_infomation", "enterprise_management"],
	},
},
{
	path: "/enterprise/enterpriseInfomation/infomations",
	name: "Infomations",
	component: () =>
		import("../views/enterprise/enterpriseInfomation/Infomations.vue"),
	meta: {
		requestAuth: true,
		index: [0, 0],
	},
},
{
	path: "/enterprise/enterpriseInfomation/authentication",
	name: "Authentication",
	component: () =>
		import(
			"../views/enterprise/enterpriseInfomation/Authentication.vue"
		),
	meta: {
		requestAuth: true,
		index: [0, 1],
	},
},
// {
// 	path: "/enterprise/enterpriseInfomation/system",
// 	name: "System",
// 	component: () =>
// 		import("../views/enterprise/enterpriseInfomation/System.vue"),
// 	meta: {
// 		requestAuth: true,
// 		index: [0, 2],
// 	},
// },
// {
// 	path: "/enterprise/enterpriseInfomation/systemEditor/:cate_id",
// 	name: "SystemEditor",
// 	component: () =>
// 		import("../views/enterprise/enterpriseInfomation/SystemEditor.vue"),
// 	props: true,
// 	meta: {
// 		requestAuth: true,
// 		index: [0, 2],
// 	},
// },
{
	path: "/enterprise/enterpriseInfomation/notice",
	name: "Notice",
	component: () =>
		import("../views/enterprise/enterpriseInfomation/Notice.vue"),
	meta: {
		requestAuth: true,
		index: [0, 2],
	},
},
{
	path: "/enterprise/enterpriseInfomation/noticeEditor",
	name: "NoticeEditor",
	component: () =>
		import("../views/enterprise/enterpriseInfomation/NoticeEditor.vue"),
	meta: {
		requestAuth: true,
		index: [0, 3],
	},
},
{
	path: "/enterprise/enterpriseInfomation/invoice",
	name: "Invoice",
	component: () =>
		import("../views/enterprise/enterpriseInfomation/Invoice.vue"),
	meta: {
		requestAuth: true,
		index: [0, 4],
	},
},
{
	path: "/enterprise/enterpriseInfomation/otherSettings",
	name: "OtherSettings",
	component: () =>
		import(
			"../views/enterprise/enterpriseInfomation/OtherSettings.vue"
		),
	meta: {
		requestAuth: true,
		index: [0, 5],
	},
},

{
	path: "/enterprise/enterpriseManagement/departmentList",
	name: "DepartmentList",
	component: () =>
		import(
			"../views/enterprise/enterpriseManagement/DepartmentList.vue"
		),
	meta: {
		requestAuth: true,
		index: [1, 0],
	},
},
{
	path: "/enterprise/enterpriseManagement/postList/:pid",
	name: "PostList",
	props: true,
	component: () =>
		import("../views/enterprise/enterpriseManagement/PostList.vue"),
	meta: {
		requestAuth: true,
		index: [1, 0],
	},
},
{
	path: "/enterprise/enterpriseManagement/stationList",
	name: "StationList",
	component: () =>
		import("../views/enterprise/enterpriseManagement/StationList.vue"),
	meta: {
		requestAuth: true,
		index: [1, 1],
	},
},
{
	path: "/enterprise/enterpriseManagement/Rolemanagement",
	name: "Rolemanagement",
	component: () =>
		import("../views/enterprise/enterpriseManagement/Rolemanagement.vue"),
	meta: {
		requestAuth: true,
		index: [1, 2],
	},
},
{
	path: "/enterprise/enterpriseManagement/InsurancesAndfund",
	name: "InsurancesAndfund",
	component: () =>
		import("../views/enterprise/enterpriseManagement/InsurancesAndfund.vue"),
	meta: {
		requestAuth: true,
		index: [1, 3],
	},
},
{
	path: "/enterprise/enterpriseManagement/labourServices",
	name: "labourServices",
	component: () =>
		import("../views/enterprise/enterpriseManagement/labourServices.vue"),
	meta: {
		requestAuth: true,
		index: [1, 4],
	},
},
{
	path: "/enterprise/enterpriseManagement/Authoritymanagement",
	name: "Authoritymanagement",
	component: () =>
		import("../views/enterprise/enterpriseManagement/Authoritymanagement.vue"),
	meta: {
		requestAuth: true,
		index: [2, 0],
	},
},
{
	path: "/enterprise/nothing",
	name: "enterprise_nothing",
	component: () =>
		import("../views/enterprise/nothing.vue"),
	meta: {
		requestAuth: true,
		index: [5, 0],
	},
},
];

// 考勤
const attendanceRoutes = [{
	path: "/attendance",
	name: "Attendance",
	//重定向
	redirect: "/attendance/nothing",
	meta: {
		headerNavSeleted: "Attendance",
		menu: [
			// "attendance_management",
			"approval_management",

			// "piecework_attendance",
		],
	},
},
{
	path: "/attendance/nothing",
	name: "AttendanceDetails",
	component: () =>
		import(
			"../views/attendance/nothing.vue"
		),
	meta: {
		requestAuth: true,
		index: [1, 0],
	},
},
// 审核模块

{
	path: "/attendance/approvalForms",
	name: "ApprovalForms",
	component: () =>
		import("../views/attendance/approvalManagement/ApprovalForms.vue"),
	meta: {
		requestAuth: true,
		index: [0, 0],
	},
},
{
	path: "/attendance/approvalFormsCate",
	name: "ApprovalFormsCate",
	component: () =>
		import(
			"../views/attendance/approvalManagement/ApprovalFormsCate.vue"
		),
	meta: {
		requestAuth: true,
		index: [1, 0],
	},
},

{
	path: "/attendance/approvalTemplateLibrary",
	name: "ApprovalTemplateLibrary",
	component: () =>
		import(
			"../views/attendance/approvalManagement/ApprovalTemplateLibrary.vue"
		),
	meta: {
		requestAuth: true,
		index: [0, 1],
	},
},
{
	path: "/attendance/approvalLog",
	name: "ApprovalLog",
	component: () =>
		import("../views/attendance/approvalManagement/ApprovalLog.vue"),
	meta: {
		requestAuth: true,
		index: [0, 2],
	},
},
{
	path: "/attendance/approvalFormsEditor",
	name: "ApprovalFormsEditor",
	props: true,
	component: () =>
		import(
			"../views/attendance/approvalManagement/ApprovalFormsEditor.vue"
		),
	meta: {
		requestAuth: true,
		index: [0, 3],
	},
},
{
	path: "/attendance/approvalFormsEditor/:id?",
	name: "ApprovalFormsEditorId",
	props: true,
	component: () =>
		import(
			"../views/attendance/approvalManagement/ApprovalFormsEditorId.vue"
		),
	meta: {
		requestAuth: true,
		index: [0, 4],
	},
},


// 考勤
{
	path: "/attendance/attendanceDetails",
	name: "AttendanceDetails",
	component: () =>
		import(
			"../views/attendance/attendanceManagement/AttendanceDetails.vue"
		),
	meta: {
		requestAuth: true,
		index: [2, 0],
	},
},
{
	path: "/attendance/attendanceReport",
	name: "AttendanceReport",
	component: () =>
		import(
			"../views/attendance/attendanceManagement/AttendanceReport.vue"
		),
	meta: {
		requestAuth: true,
		index: [2, 1],
	},
},
{
	path: "/attendance/punchWay",
	name: "PunchWay",
	component: () =>
		import("../views/attendance/attendanceManagement/PunchWay.vue"),
	meta: {
		requestAuth: true,
		index: [2, 3],
	},
},
{
	path: "/attendance/machinePunchEditor/:id?",
	name: "MachinePunchEditor",
	component: () =>
		import(
			"../views/attendance/attendanceManagement/MachinePunchEditor.vue"
		),
	props: true,
	meta: {
		requestAuth: true,
		index: [0, 2],
	},
},
{
	path: "/attendance/miniprogramPunchWayEditor/:id?",
	name: "MiniprogramPunchWayEditor",
	component: () =>
		import(
			"../views/attendance/attendanceManagement/MiniprogramPunchEditor.vue"
		),
	props: true,
	meta: {
		requestAuth: true,
		index: [0, 2],
	},
},
{
	path: "/attendance/attendanceRules",
	name: "AttendanceRules",
	component: () =>
		import(
			"../views/attendance/attendanceManagement/AttendanceRules.vue"
		),
	meta: {
		requestAuth: true,
		index: [0, 3],
	},
},
{
	path: "/attendance/attendanceRulesEditor/:id?",
	name: "AttendanceRulesEditor",
	component: () =>
		import(
			"../views/attendance/attendanceManagement/AttendanceRulesEditor.vue"
		),
	props: true,
	meta: {
		requestAuth: true,
		index: [0, 3],
	},
},
	// {
	//     path: "/attendance/processConfiguration",
	//     name: "ProcessConfiguration",
	//     component: () =>
	//         import(
	//             "../views/attendance/pieceworkAttendance/ProcessConfiguration.vue"
	//         ),
	//     meta: {
	//         requestAuth: true,
	//         index: [1, 0],
	//     },
	// },
	// {
	//     path: "/attendance/employeePieceworkFactor",
	//     name: "EmployeePieceworkFactor",
	//     component: () =>
	//         import(
	//             "../views/attendance/pieceworkAttendance/EmployeePieceworkFactor.vue"
	//         ),
	//     meta: {
	//         requestAuth: true,
	//         index: [1, 1],
	//     },
	// },
	// {
	//     path: "/attendance/employeePieceworkFactorGrades",
	//     name: "EmployeePieceworkFactorGrades",
	//     component: () =>
	//         import(
	//             "../views/attendance/pieceworkAttendance/EmployeePieceworkFactorGrades.vue"
	//         ),
	//     meta: {
	//         requestAuth: true,
	//         index: [1, 1],
	//     },
	// },
	// {
	//     path: "/attendance/employeePieceworkStatistics",
	//     name: "EmployeePieceworkStatistics",
	//     component: () =>
	//         import(
	//             "../views/attendance/pieceworkAttendance/EmployeePieceworkStatistics.vue"
	//         ),
	//     meta: {
	//         requestAuth: true,
	//         index: [1, 2],
	//     },
	// },


	// {
	// 	path: "/attendance/corpWechatApprovalLog",
	// 	name: "CorpWechatApprovalLog",
	// 	component: () =>
	// 		import("../views/attendance/approvalManagement/CorpWechatApprovalLog.vue"),
	// 	meta: {
	// 		requestAuth: true,
	// 		index: [1, 3],
	// 	},
	// },
];

// 合同
const contractRoutes = [{
	path: "/contract",
	name: "Contract",
	//重定向
	redirect: "/contract/nothing",
	meta: {
		headerNavSeleted: "Contract",
		menu: ["contract_management"],
	},
},
{
	path: "/contract/standardContractsList",
	name: "StandardContractsList",
	component: () =>
		import(
			"../views/contract/contractManagement/StandardContractsList.vue"
		),
	meta: {
		requestAuth: true,
		index: [0, 0],
	},
},



{
	path: "/contract/employeeContractInformation",
	name: "EmployeeContractInformation",
	component: () =>
		import(
			"../views/contract/contractManagement/EmployeeContractInformation.vue"
		),
	meta: {
		requestAuth: true,
		index: [0, 1],
	},
},
{
	path: "/contract/enterPriseContractInformation",
	name: "EnterPriseContractInformation",
	component: () =>
		import(
			"../views/contract/contractManagement/EnterPriseContractInformation.vue"
		),
	meta: {
		requestAuth: true,
		index: [0, 2],
	},
},
{
	path: "/contract/signatureRecord",
	name: "SignatureRecord",
	component: () =>
		import(
			"../views/contract/contractManagement/SignatureRecord.vue"
		),
	meta: {
		requestAuth: true,
		index: [0, 3],
	},
},
{
	path: "/contract/customContractList",
	name: "CustomContractList",
	component: () =>
		import(
			"../views/contract/contractManagement/CustomContractList.vue"
		),
	meta: {
		requestAuth: true,
		index: [0, 4],
	},
},

{
	path: "/contract/legalAdviser",
	name: "LegalAdviser",
	component: () =>
		import("../views/contract/platformServices/LegalAdviser.vue"),
	meta: {
		requestAuth: true,
		index: [1, 0],
	},
},
{
	path: "/contract/contractTemplate",
	name: "ContractTemplate",
	component: () =>
		import("../views/contract/platformServices/ContractTemplate.vue"),
	meta: {
		requestAuth: true,
		index: [1, 1],
	},
},
{
	path: "/contract/nothing",
	name: "contract_nothing",
	component: () =>
		import("../views/contract/nothing.vue"),
	meta: {
		requestAuth: true,
		index: [5, 0],
	},
},
];

// 发薪
const payRoutes = [{
	path: "/pay",
	name: "Pay",
	//重定向
	redirect: "/pay/nothing",
	meta: {
		headerNavSeleted: "Pay",
		menu: [
			"salary_management",
			// "insurance_management",
			// "payslip_management",
		],
	},
},
{
	path: "/pay/monthlyPayroll",
	name: "MonthlyPayroll",
	component: () =>
		import("../views/pay/salaryManagement/MonthlyPayroll.vue"),
	meta: {
		requestAuth: true,
		index: [0, 3],
	},
},
{
	path: "/pay/salaryAudit",
	name: "SalaryAudit",
	component: () =>
		import("../views/pay/salaryManagement/SalaryAudit.vue"),
	meta: {
		requestAuth: true,
		index: [0, 1],
	},
},
{
	path: "/pay/salaryAuditEditor/:id?",
	name: "SalaryAuditEditor",
	props: true,
	component: () =>
		import("../views/pay/salaryManagement/SalaryAuditEditor.vue"),
	meta: {
		requestAuth: true,
		index: [0, 1],
		layout: "header-only",
	},
},
{
	path: "/pay/salaryAuditPrint/:id?",
	name: "SalaryAuditPrint",
	props: true,
	component: () =>
		import("../views/pay/salaryManagement/SalaryAuditPrint.vue"),
	meta: {
		requestAuth: true,
		index: [0, 1],
		layout: "single",
	},
},
{
	path: "/pay/salarySplit",
	name: "SalarySplit",
	component: () =>
		import("../views/pay/salaryManagement/SalarySplit.vue"),
	meta: {
		requestAuth: true,
		index: [0, 2],
	},
},
// --------------------
{
	path: "/pay/monthlyAttendanceManagement",
	name: "MonthlyAttendanceManagement",
	component: () =>
		import("../views/pay/salaryManagement/monthlyAttendanceManagement.vue"),
	meta: {
		requestAuth: true,
		index: [0, 0],
	},
},
{
	path: "/pay/monthlyWageStatistics",
	name: "MonthlyWageStatistics",
	component: () =>
		import("../views/pay/salaryManagement/monthlyWageStatistics.vue"),
	meta: {
		requestAuth: true,
		index: [0, 1],
	},
},
{
	path: "/pay/salarySplitList2",
	name: "SalarySplitList2",
	component: () =>
		import("../views/pay/salaryManagement/SalarySplitList.vue"),
	meta: {
		requestAuth: true,
		index: [0, 2],
	},
},
{
	path: "/pay/salaryAllocation",
	name: "SalaryAllocation",
	component: () =>
		import("../views/pay/salaryManagement/salaryAllocation.vue"),
	meta: {
		requestAuth: true,
		index: [0, 3],
	},
},
{
	path: "/pay/payrollRecord",
	name: "PayrollRecord",
	component: () =>
		import("../views/pay/salaryManagement/payrollRecord.vue"),
	meta: {
		requestAuth: true,
		index: [0, 2],
	},
},
// ---------------------
{
	path: "/pay/salarySplitList",
	name: "SalarySplitList",
	component: () =>
		import("../views/pay/salaryManagement/SalarySplitList.vue"),
	meta: {
		requestAuth: true,
		index: [0, 0],
	},
},
{
	path: "/pay/salarySplitEditor/:id?",
	name: "SalarySplitEditor",
	component: () =>
		import("../views/pay/salaryManagement/SalarySplitEditor.vue"),
	props: true,
	meta: {
		requestAuth: true,
		index: [0, 2],
	},
},
{
	path: "/pay/salarySplitTable/:id?",
	name: "SalarySplitTable",
	component: () =>
		import("../views/pay/salaryManagement/SalarySplitTable.vue"),
	props: true,
	meta: {
		requestAuth: true,
		layout: "header-only",
		index: [0, 2],
	},
},
{
	path: "/pay/salarySplitPrint/:id?",
	name: "SalarySplitPrint",
	component: () =>
		import("../views/pay/salaryManagement/SalarySplitPrint.vue"),
	props: true,
	meta: {
		requestAuth: true,
		layout: "single",
		index: [0, 2],
	},
},
{
	path: "/pay/salaryPayment",
	name: "SalaryPayment",
	component: () =>
		import("../views/pay/salaryManagement/SalaryPayment.vue"),
	meta: {
		requestAuth: true,
		index: [0, 1],
	},
},
{
	path: "/pay/salaryReport",
	name: "SalaryReport",
	component: () =>
		import("../views/pay/salaryManagement/SalaryReport.vue"),
	meta: {
		requestAuth: true,
		index: [0, 2],
	},
},
{
	path: "/pay/EmployeeDebit",
	name: "EmployeeDebit",
	component: () =>
		import("../views/pay/salaryManagement/EmployeeDebit.vue"),
	meta: {
		requestAuth: true,
		index: [0, 3],
	},
},
{
	path: "/pay/employeeDebitDetails",
	name: "EmployeeDebitDetails",
	component: () =>
		import("../views/pay/salaryManagement/EmployeeDebitDetails.vue"),
	meta: {
		requestAuth: true,
		index: [0, 3],
	},
},
{
	path: "/pay/employeeDebitDeduction",
	name: "EmployeeDebitDeduction",
	component: () =>
		import("../views/pay/salaryManagement/EmployeeDebitDeduction.vue"),
	meta: {
		requestAuth: true,
		index: [0, 3],
	},
},
{
	path: "/pay/insuranceManagement",
	name: "InsuranceManagement",
	component: () =>
		import("../views/pay/insuranceManagement/InsuranceManagement.vue"),
	meta: {
		requestAuth: true,
		index: [1, 0],
	},
},
{
	path: "/pay/paysWages",
	name: "PaysWages",
	component: () => import("../views/pay/payslipManagement/PaysWages.vue"),
	meta: {
		requestAuth: true,
		index: [2, 0],
	},
},
{
	path: "/pay/paysLogs",
	name: "PaysLogs",
	component: () => import("../views/pay/payslipManagement/PaysLogs.vue"),
	meta: {
		requestAuth: true,
		index: [2, 1],
	},
},
{
	path: "/pay/nothing",
	name: "pay_nothing",
	component: () => import("../views/pay/nothing.vue"),
	meta: {
		requestAuth: true,
		index: [5, 0],
	},
},
];

// 招聘
const recruitmentRoute = [{
	path: "/recruitment",
	name: "Recruitment",
	//重定向
	redirect: "/recruitment/nothing",
	meta: {
		headerNavSeleted: "Recruitment",
		menu: [
			// "talents_intelligent_recommendation",
			// "recruit_workers",
			"recruitment_management",
			"recruit_workers",
			"recruitment_overview",
		],
	},
},
{
	path: "/recruitment/recruitmentPost",
	name: "RecruitmentPost",
	component: () =>
		import(
			"../views/recruitment/recruitmentManagement/RecruitmentPost.vue"
		),
	meta: {
		requestAuth: true,
		index: [0, 0],
	},
},
{
	path: "/recruitment/recruitmentTeam",
	name: "RecruitmentTeam",
	component: () =>
		import(
			"../views/recruitment/recruitmentManagement/RecruitmentTeam.vue"
		),
	meta: {
		requestAuth: true,
		index: [0, 1],
	},
},
// {
// 	path: "/recruitment/overview",
// 	name: "RecruitmentOverview",
// 	component: () => import("../views/recruitment/RecruitmentOverview.vue"),
// 	meta: {
// 		requestAuth: true,
// 		// index: [0, 0],
// 		index: [3, 0],
// 	},
// },
{
	path: "/recruitment/recruiting",
	name: "Recruiting",
	component: () =>
		import("../views/recruitment/recruitWorkers/Recruiting.vue"),
	meta: {
		requestAuth: true,
		index: [1, 0],
	},
},
{
	path: "/recruitment/eliminated",
	name: "Eliminated",
	component: () =>
		import("../views/recruitment/recruitWorkers/Eliminated.vue"),
	meta: {
		requestAuth: true,
		index: [1, 1],
	},
},
// {
// 	path: "/recruitment/recruited",
// 	name: "Recruited",
// 	component: () =>
// 		import("../views/recruitment/recruitWorkers/Recruited.vue"),
// 	meta: {
// 		requestAuth: true,
// 		index: [1, 1],
// 	},
// },
// {
// 	path: "/recruitment/eliminated",
// 	name: "Eliminated",
// 	component: () =>
// 		import("../views/recruitment/recruitWorkers/Eliminated.vue"),
// 	meta: {
// 		requestAuth: true,
// 		index: [1, 2],
// 	},
// },
{
	path: "/recruitment/talentPool",
	name: "TalentPool",
	component: () =>
		import("../views/recruitment/recruitWorkers/TalentPool.vue"),
	meta: {
		requestAuth: true,
		index: [1, 3],
	},
},
// {
// 	path: "/recruitment/recruitmentPost",
// 	name: "RecruitmentPost",
// 	component: () =>
// 		import(
// 			"../views/recruitment/recruitmentManagement/RecruitmentPost.vue"
// 		),
// 	meta: {
// 		requestAuth: true,
// 		index: [2, 0],
// 	},
// },
{
	path: "/recruitment/overview",
	name: "RecruitmentOverview",
	component: () => import("../views/recruitment/RecruitmentOverview.vue"),
	meta: {
		requestAuth: true,
		index: [2, 0],
	},
},
{
	path: "/recruitment/recruitmentEditor/:id?",
	name: "RecruitmentEditor",
	component: () =>
		import(
			"../views/recruitment/recruitmentManagement/RecruitmentEditor.vue"
		),
	props: true,
	meta: {
		requestAuth: true,
		index: [3, 0],
	},
},
{
	path: "/recruitment/nothing",
	name: "recruitment_nothing",
	component: () => import("../views/recruitment/nothing.vue"),
	meta: {
		requestAuth: true,
		index: [5, 0],
	},
},
	// {
	// 	path: "/recruitment/recruitmentTeam",
	// 	name: "RecruitmentTeam",
	// 	component: () =>
	// 		import(
	// 			"../views/recruitment/recruitmentManagement/RecruitmentTeam.vue"
	// 		),
	// 	meta: {
	// 		requestAuth: true,
	// 		index: [2, 1],
	// 	},
	// },
];

// 员工
const employeeRoute = [{
	path: "/employee",
	name: "Employee",
	//重定向
	redirect: "/employee/nothing",
	meta: {
		headerNavSeleted: "Employee",
		menu: [
			"employee_information",
			//  "employee_relations"
		],
	},
},
{
	path: "/employee/employeeRoster",
	name: "EmployeeRoster",
	component: () =>
		import("../views/employee/employeeInformation/EmployeeRoster.vue"),
	meta: {
		requestAuth: true,
		index: [0, 0],
	},
},
{
	path: "/employee/employeeDetail/:isSave/:id/:Induction?",
	name: "EmployeeDetail",
	component: () =>
		import("../views/employee/employeeInformation/EmployeeDetail.vue"),
	props: true,
	meta: {
		requestAuth: true,
		index: [0, 0],
	},
},

// {
// 	path: "/employee/employeeFiles",
// 	name: "EmployeeFiles",
// 	component: () =>
// 		import("../views/employee/employeeInformation/EmployeeFiles.vue"),
// 	meta: {
// 		requestAuth: true,
// 		index: [0, 1],
// 	},
// },
// {
// 	path: "/employee/employeeRating",
// 	name: "EmployeeRating",
// 	component: () =>
// 		import("../views/employee/employeeInformation/EmployeeRating.vue"),
// 	meta: {
// 		requestAuth: true,
// 		index: [0, 2],
// 	},
// },
{
	path: "/employee/entryManagement",
	name: "EntryManagement",
	component: () =>
		import("../views/employee/employeeRelations/EntryManagement.vue"),
	meta: {
		requestAuth: true,
		index: [0, 1],
	},
},
{
	path: "/employee/regularManagement",
	name: "RegularManagement",
	component: () =>
		import("../views/employee/employeeRelations/RegularManagement.vue"),
	meta: {
		requestAuth: true,
		index: [0, 2],
	},
},
{
	path: "/employee/leaveManagement",
	name: "LeaveManagement",
	component: () =>
		import("../views/employee/employeeRelations/LeaveManagement.vue"),
	meta: {
		requestAuth: true,
		index: [0, 3],
	},
},
{
	path: "/employee/whiteNoteReview",
	name: "whiteNoteReview",
	component: () =>
		import("../views/employee/employeeInformation/whiteNoteReview.vue"),
	meta: {
		requestAuth: true,
		index: [0, 4],
	},
},
{
	// 调岗
	path: "/employee/employeePostTransfer",
	name: "employeePostTransfer",
	component: () =>
		import("../views/employee/employeeInformation/EmployeePostTransfer.vue"),
	props: true,
	meta: {
		requestAuth: true,
		index: [0, 5],
	},
},
{
	// 借岗
	path: "/employee/employeeBorrow",
	name: "employeeBorrow",
	component: () =>
		import("../views/employee/employeeInformation/EmployeeBorrow.vue"),
	props: true,
	meta: {
		requestAuth: true,
		index: [0, 6],
	},
},
{
	path: "/employee/applicationForm",
	name: "applicationForm",
	component: () =>
		import("../views/employee/employeeApplication/applicationForm.vue"),
	meta: {
		requestAuth: true,
		index: [1, 0],
	},
},
{
	path: "/employee/applicationRecord",
	name: "applicationRecord",
	component: () =>
		import("../views/employee/employeeApplication/applicationRecord.vue"),
	meta: {
		requestAuth: true,
		index: [1, 1],
	},
},
{
	path: "/employee/nothing",
	name: "employee_nothing",
	component: () =>
		import("../views/employee/nothing.vue"),
	meta: {
		requestAuth: true,
		index: [5, 0],
	},
},
// {
// 	path: "/employee/entryManagement",
// 	name: "EntryManagement",
// 	component: () =>
// 		import("../views/employee/employeeRelations/EntryManagement.vue"),
// 	meta: {
// 		requestAuth: true,
// 		index: [1, 0],
// 	},
// },
// {
// 	path: "/employee/regularManagement",
// 	name: "RegularManagement",
// 	component: () =>
// 		import("../views/employee/employeeRelations/RegularManagement.vue"),
// 	meta: {
// 		requestAuth: true,
// 		index: [1, 1],
// 	},
// },
{
	path: "/employee/employeeInsuranceSettings",
	name: "EmployeeInsuranceSettings",
	component: () =>
		import(
			"../views/employee/employeeRelations/EmployeeInsuranceSettings.vue"
		),
	meta: {
		requestAuth: true,
		index: [1, 1],
	},
},
	// {
	// 	path: "/employee/leaveManagement",
	// 	name: "LeaveManagement",
	// 	component: () =>
	// 		import("../views/employee/employeeRelations/LeaveManagement.vue"),
	// 	meta: {
	// 		requestAuth: true,
	// 		index: [1, 2],
	// 	},
	// },
];

// 灵活用工
const flexibleEmploymentRoute = [{
	path: "/flexibleEmployment",
	name: "FlexibleEmployment",
	//重定向
	redirect: "/flexibleEmployment/demandList",
	meta: {
		headerNavSeleted: "FlexibleEmployment",
		// menu: ["labor_demand", "employment_order", "serviceMarket"],
		menu: ["labor_demand", "serviceMarket"],
	},
},
{
	path: "/flexibleEmployment/demandList",
	name: "DemandList",
	component: () =>
		import("../views/flexibleEmployment/laborDemand/DemandList.vue"),
	meta: {
		requestAuth: true,
		index: [0, 0],
	},
},
{
	path: "/flexibleEmployment/beforeDemand",
	name: "BeforeDemand",
	component: () =>
		import("../views/flexibleEmployment/laborDemand/BeforeDemand.vue"),
	meta: {
		requestAuth: true,
		index: [0, 1],
	},
},
{
	path: "/flexibleEmployment/orderList",
	name: "OrderList",
	component: () =>
		import("../views/flexibleEmployment/employmentOrder/OrderList.vue"),
	meta: {
		requestAuth: true,
		index: [1, 0],
	},
},
{
	path: "/flexibleEmployment/abnormalOrder",
	name: "AbnormalOrder",
	component: () =>
		import(
			"../views/flexibleEmployment/employmentOrder/AbnormalOrder.vue"
		),
	meta: {
		requestAuth: true,
		index: [1, 1],
	},
},
{
	path: "/flexibleEmployment/platformServices",
	name: "PlatformServices",
	component: () =>
		import(
			"../views/flexibleEmployment/serviceMarket/PlatformServices.vue"
		),
	meta: {
		requestAuth: true,
		index: [2, 0],
	},
},
{
	path: "/flexibleEmployment/serviceSupermarket",
	name: "ServiceSupermarket",
	component: () =>
		import(
			"../views/flexibleEmployment/serviceMarket/ServiceSupermarket.vue"
		),
	meta: {
		requestAuth: true,
		index: [2, 1],
	},
},
];

const cockpitRoute = [{
	path: "/cockpit",
	name: "Cockpit",
	component: () => import("../views/cockpit/Cockpit.vue"),
	meta: {
		layout: "header-only",
		requestAuth: true,
		headerNavSeleted: "Cockpit",
	},
},];
// 为子路由装载父路由meta数据 //为侧边栏加载
//没有设置路由守卫的权限，没按钮但是页面是可以直接跳的
((routes) => {
	// console.log(routes)
	routes.forEach((block) => {
		for (let i = 1; i < block.length; i++) {
			const route = block[i];
			route.meta["headerNavSeleted"] = block[0].meta.headerNavSeleted;
			let menu = JSON.parse(JSON.stringify(block[0].meta.menu));
			menu.push(route.meta.index);
			route.meta["menu"] = menu;
		}
	});
})([
	enterpriseRoutes,
	attendanceRoutes,
	payRoutes,
	recruitmentRoute,
	employeeRoute,
	contractRoutes,
	flexibleEmploymentRoute,
]);

const routes = [
	...baseRoutes,
	...enterpriseRoutes,
	...attendanceRoutes,
	...payRoutes,
	...recruitmentRoute,
	...employeeRoute,
	...contractRoutes,
	...flexibleEmploymentRoute,
	...cockpitRoute,
];

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes,
});

export default router;
