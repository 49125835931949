/* eslint-disable no-unused-vars */
import axios from "axios";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    /**
     * 获取员工花名册{ page, size, keywords, status, nav_type }
     */
    getEmployeeList(_, params) {
        params.ifGetInfoFddUser = 1
        // let params = {
        //     page,
        //     ifGetInfoFddUser: 1,
        // };
        // if (size) params.size = size;
        // if (keywords) params.keywords = keywords;
        if (params.status) params["search[status]"] = params.status;
        // // params.status = 0
        // if (nav_type) params.nav_type = nav_type;
        console.log("搜索参数", params);
        return axios.get("/web/staff/list", {
            params,
        });
    },
    //搜索员工花名册
    getSearchEmployee(_, params) {

        params.ifGetInfoFddUser = 1
        return axios.get("/web/staff/list", {
            params,
        });
    },

    /**
     * 下载员工花名册导入模板
     */
    getEmployeeTemplate(_, data) {
        return axios.get("/web/config/download", {
            params: {
                name: data,
                uid: JSON.parse(localStorage.getItem("user")).uid
            },
            responseType: "blob",
        });
    },

    /**
     * 导入员工
     */
    postImportEmployee(_, fileUrl) {
        return axios.post(
            "/web/staff/import",
            {
                fileUrl,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 设置员工为管理员
     */
    setEmployeeAdmin(_, params) {
        return axios.get("/web/staff/setManage", {
            params,
        });
    },

    /**
     * 获取部门/职务列表
     */
    getDepartmentList() {
        return axios.get("/web/department/list", {
            params: {
                label: 1
            }
        });
    },
    /*
    *获取所在项目
    */
    getProjectList() {
        return axios.get("/web/department/list", {
            params: {
                label: 2
            }
        });
    },

    /**
     * 获取岗位列表
     */
    getStationList() {
        return axios.get("/web/department/getStationList");
    },

    /**
     * 调薪
     */
    postChangeSalary(_, { id, salary_base, normal_work_salary }) {
        return axios.post(
            "/web/staff/setFields",
            {
                id,
                salary_base,
                normal_work_salary,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 调岗
     */
    postPostTransfer(_, params) {
        return axios.post(
            "/web/staff/transfer",
            params,
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },
    /**
     *  借岗申请
     */
    borrowPostTransfer(_, params) {
        return axios.post(
            "/web/staff/borrow",
            params,
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },
    /**
     * 借岗列表信息
     */
    getBorrowTable(_, params) {
        return axios.get("/web/staff/borrowList", { params });
    },
    /**
     * 中止借岗
     * 
     */
    stopBorrow(_, params) {
        return axios.get(
            "/web/staff/revokeBorrow",
            { params });
    },
    // 删除借岗记录 
    delBorrow(_, params) {
        return axios.get(
            "/web/staff/delBorrow",
            { params });
    },
    // 借岗人员导入考勤表 
    borrowImport(_, params) {
        return axios.post(
            "/web/attendance/borrowImport",
            params,
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },
    /**
     * 同步用户到打卡机
     */
    getSyncEmployeePunchMachine(_, { id, user_name }) {
        return axios.get("/web/punch/pushDeviceUserPunchFace", {
            params: {
                uid: id,
                uname: user_name,
            },
        });
    },

    /**
     * 批量同步用户到打卡机
     */
    getSyncEmployeePunchMachineBatch(_, { ids, user_names }) {
        return axios.get("/web/punch/pushDeviceUserPunchFaceMulti", {
            params: {
                uid: ids,
                uname: user_names,
            },
        });
    },

    /**
     * 同步打卡机记录
     */
    getSyncPunchMachineLog() {
        return axios.get("/web/punch/getDeviceUserPunch", {});
    },

    /**
     * 添加员工
     */
    postCreateEmployee(
        _,
        { mobile, real_name, department_id, duty_id, station_id }
    ) {
        return axios.post(
            "/web/staff/create",
            {
                mobile,
                real_name,
                department_id,
                duty_id,
                station_id,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 获取员工信息
     */
    getEmployeeDetail(_, { id }) {
        return axios.get("/web/staff/detail", {
            params: {
                id,
            },
        });
    },

    /**
     * 获取户口性质
     */
    getResidenceTypeCertTypeData() {
        return axios.get("/web/config/allData", {
            params: {
                types: "residence_type,cert_type",
            },
        });
    },

    /**
     * 员工批量离职
     */
    postEmployeeQuit(_, { staff_ids, quit_time, quit_type, reason }) {
        return axios.post(
            "/web/staffQuit/create",
            {
                staff_ids,
                quit_time,
                quit_type,
                reason,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 编辑员工信息
     */
    postEmployeeDetail(_, params) {
        return axios.post(
            "/web/staff/edit",
            params,
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },
    /* 
    `导出花名册
    */
    // exportRosters(_, params) {
    //     return axios.get("/web/staff/export", {
    //         params
    //     });
    // },
    async exportRosters(_, params) {
        console.log(params);
        let url = "https://hrv1-end.yx2.qushiyun.com/web/staff/export?id=" + params.id;
        var xhr = new XMLHttpRequest(); //定义http请求对象
        xhr.open("GET", url, true);
        let token = await JSON.parse(localStorage.getItem("user")).token;
        xhr.setRequestHeader("Authorization", "Bearer " + token);
        xhr.send();
        xhr.responseType = "blob"; // 返回类型blob
        xhr.onload = function () {
            // 定义请求完成的处理函数，请求前也可以增加加载框/禁用下载按钮逻辑
            if (this.status === 200) {
                var blob = this.response;
                console.log(this.response);
                var reader = new FileReader();
                reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
                reader.onload = function (e) {
                    var a = document.createElement("a");
                    a.download = params.name; //自定义下载文件名称
                    a.href = e.target.result;
                    document.body.appendChild(a); // 修复firefox中无法触发click
                    a.click();
                };
            }
        };
    },

    /**
     * 花名册获取员工详细
     */
    getEmployeeRosterDetail(_, { staff_id }) {
        return axios.get("/web/staff/files", {
            params: {
                staff_id,
            },
        });
    },
    // 获取城市列表
    getAllCity() {
        return axios.get("/web/upload/area");
    },

    /**
     * 获取员工统计
     */
    getEmployeeStatistics() {
        return axios.get("/web/staff/statistics");
    },

    /**
     * 获取离职员工统计
     */
    getLeaveEmployeeStatistics() {
        return axios.get("/web/staffQuit/statistics");
    },

    /**
     * 获取添加员工二维码
     */
    getEmployeeQrcode() {
        return axios.get("/web/staff/qrcode");
    },

    /**
     * 获取离职员工评价列表
     */
    getEmployeeRatingList(_, { page, size, keywords }) {
        let params = {
            page,
            "search[status]": 5,
        };
        if (size) params.size = size;
        if (keywords) params.keywords = keywords;
        return axios.get("/web/staff/list", {
            params,
        });
    },

    /**
     * 离职员工评级
     */
    postEmployeeRating(_, { id, grade }) {
        return axios.post(
            "/web/staff/grade",
            {
                id,
                grade,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 获取入职管理列表
     */
    getEmployeeEntryList(_, { page, size, keywords }) {
        let params = {
            page,
            status: 0,
            ifGetInfoFddUser: 1,
        };
        if (size) params.size = size;
        if (keywords) params.keywords = keywords;
        return axios.get("/web/staff/list", {
            params,
        });
    },

    /**
     * 黑名单检测
     */
    getBlackList(_, { ids }) {
        return axios.get("/web/staff/checkBlackList", {
            params: {
                ids,
            },
        });
    },

    /**
     * 确定入职
     */
    passEmployeeEntry(_, params) {
        return axios.post(
            "/web/staff/check",
            params,
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 获取用工类型下拉菜单
     */
    getLabourTypeData() {
        return axios.get("/web/config/allData", {
            params: {
                types: "labour_type",
            },
        });
    },

    /**
     * 获取合同套餐模板
     */
    getFddTemplateCate(_, { staff_id, is_social_security, labour_type }) {
        return axios.get("/web/contract/getFddTemplateCate", {
            params: {
                staff_id,
                is_social_security,
                labour_type,
            },
        });
    },

    /**
     * 获取合同套餐模板
     */
    getFddTemplateList(_, { id, staff_id }) {
        return axios.get("/web/contract/getFddTemplateList", {
            params: {
                id,
                staff_id,
            },
        });
    },

    /**
     * 获取合同套餐模板
     */
    getFddTemplate(_, { staff_id, template_id }) {
        return axios.get("/contract/printing/user", {
            params: {
                staff_id,
                template_id,
            },
        });
    },

    /**
     * 获取转正管理列表
     */
    getEmployeeRegularList(_, { page, size, keywords }) {
        let params = {
            page,
        };
        if (size) params.size = size;
        if (keywords) params.keywords = keywords;
        return axios.get("/web/staffRegular/list", {
            params,
        });
    },

    /**
     * 编辑试用期
     */
    postEditPeriod(_, { id, probation_period }) {
        return axios.post(
            "/web/staff/setFields",
            {
                id,
                probation_period,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 获取所有保险种类
     */
    getInsuranceList() {
        return axios.get("/web/config/insurance");
    },

    /**
     * 员工转正
     */
    postEmployeeRegular(
        _,
        { ids, insurance, is_social_security, insurance_premium_type }
    ) {
        return axios.post(
            "/web/staff/setFormal",
            {
                ids,
                insurance,
                is_social_security,
                insurance_premium_type,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    postRefuseRegular(_, { ids }) {
        return axios.post(
            // "/web/staff/checked",
            "/web/staff/refuse",
            {
                ids,
                // status: -1,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 获取离职管理列表
     */
    getEmployeeLeaveList(_, { page, size, keywords }) {
        let params = {
            page,
            ifGetInfoFddUser: 1,
        };
        if (size) params.size = size;
        if (keywords) params.keywords = keywords;
        return axios.get("/web/staffQuit/list", {
            params,
        });
    },

    /**
     * 自动离职
     */
    addAutoLeave(_, { staff_id, quit_time }) {
        return axios.post(
            "/web/staffQuit/add",
            {
                staff_id,
                quit_time,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 办理离职
     */
    postEmployeeLeave(_, { ids }) {
        return axios.post(
            "/web/staffQuit/checked",
            {
                ids,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 获取催告通知信息
     */
    getReminderNoticeDetail(_, { id }) {
        return axios.get("/web/staffQuit/selfLeaving", {
            params: {
                id,
            },
        });
    },

    /**
     * 第一次催告
     */
    sendInterpellate(
        _,
        {
            staff_id,
            begin_nowork_time,
            end_nowork_time,
            quit_remind_time,
            absenteeism,
        }
    ) {
        return axios.post(
            "/web/staff/sendInterpellate",
            {
                staff_id,
                begin_nowork_time,
                end_nowork_time,
                quit_remind_time,
                absenteeism,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 计算日期间隔天数
     */
    diffDay(_, { begin_date, end_date }) {
        return axios.post(
            "/web/staff/diffDay",
            {
                begin_date,
                end_date,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 解除劳动合同
     */
    fire(_, { staff_id }) {
        return axios.post(
            "/web/staff/fire",
            {
                staff_id,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 第二次催告-获取离职信息
     */
    quitDetail(_, { staff_id }) {
        return axios.get("/web/staff/quitDetail", {
            params: {
                staff_id,
            },
        });
    },

    /**
     * 解除催告
     */
    cancelInterpellate(_, { staff_id }) {
        return axios.post(
            "/web/staff/cancelInterpellate",
            {
                staff_id,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
