<template>
  <el-container>
    <el-header style="height: 120px; padding: 0;">
      <Header></Header>
    </el-header>
    <el-main>
      <router-view />
    </el-main>
  </el-container>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  components: {
    Header: Header,
  },
};
</script>

<style lang="scss" scoped>
.el-main {
  padding: 0;
}
</style>
