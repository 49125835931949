import axios from "axios";

const state = {
	user: JSON.parse(localStorage.getItem("user")) || {},
	// logo: require("@/assets/logo.png"),
	owner: JSON.parse(localStorage.getItem("owner")) || {
		logo: require("@/assets/logo.png"),
	},
	authority: JSON.parse(localStorage.getItem("authority")) || {}
};

const getters = {
	user(state) {
		return state.user;
	},

	token(state) {
		return state.user.token;
	},

	owner(state) {
		return state.owner;
	},

	authority(state) {
		return state.authority
	},

	header(state) {
		return state.authority.header
	},

	role(state) {
		return state.user.role
	}
};

const mutations = {
	SET_USER(state, user) {
		state.user = user;
		localStorage.setItem("user", JSON.stringify(user));
	},

	SET_OWNER(state, owner) {
		state.owner = owner;
		localStorage.setItem("owner", JSON.stringify(owner));
	},

	SET_AUTHORITY(state, authority) {
		state.authority = authority;
		localStorage.setItem("authority", JSON.stringify(authority));
	}
};

const actions = {
	getOwnerInfo({
		commit
	}, {
		owner_id
	}) {
		axios
			.get("/web/index/get_owner", {
				params: {
					owner_id,
				},
			})
			.then((res) => {
				console.log(res);
				commit("SET_OWNER", res.data.data.detail);
			})
			.catch((e) => {
				console.error(e);
			});
	},

	login({
		commit
	}, {
		account,
		password,
		type
	}) {
		return new Promise((resolve, reject) => {
			axios
				.post("/web/login", {
					account,
					password: password,
					type,
					no_sign: 1,
				})
				.then((res) => {
					commit("SET_USER", res.data.data);
					resolve(res);
				})
				.catch((e) => {
					console.error(e);
					reject(e);
				});
		});
	},

	logout({
		commit
	}) {
		commit("SET_USER", {});
		commit("SET_AUTHORITY", {});
	},

	/**
	 * 注册法大大
	 */
	registerFdd() {
		axios
			.get("/contract/fdd_user/register_account")
			.then((res) => {
				console.log(res);
			})
			.catch((e) => {
				console.error(e);
			});
	},

	/**
	 * 校验手机
	 */
	verifyPhone(_, {
		mobile
	}) {
		return axios.post(
			"/web/register", {
				type: 1,
				mobile,
			}, {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			}
		);
	},

	/**
	 * 注册企业账号
	 */
	register(
		_, {
			mobile,
			phone_code,
			name,
			industry,
			staff_num,
			password,
			owner_id
		}
	) {
		return axios.post(
			"/web/register", {
				type: 2,
				mobile,
				phone_code,
				name,
				industry,
				staff_num,
				password,
				owner_id,
			}, {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			}
		);
	},

	/**
	 * 修改密码
	 */
	forgetPwd(_, {
		phone,
		phone_code,
		newPassword,
		confirmPassword
	}) {
		return axios.post(
			"/web/auth/updateLoginPassword", {
				phone,
				phone_code,
				newPassword,
				confirmPassword,
			}, {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			}
		);
	},

	/**
	 * 发送验证码
	 */
	sendCode(_, {
		mobile,
		type
	}) {
		return axios.get("/web/auth/sendPhoneCode", {
			params: {
				mobile,
				type,
			},
		});
	},

	/**
	 * 获取首页信息
	 */
	getIndex() {
		return axios.get("/web/index/home");
	},

	/**
	 * 获取提醒事项列表
	 */
	getScheduleList(_, {
		month
	}) {
		return axios.get("/web/reminder/index", {
			params: {
				month,
			},
		});
	},

	/**
	 * 企业站内消息
	 */
	getRemind() {
		return axios.get("/web/station_msg/list");
	},

	/**
	 * 企业站内消息-全部已读
	 */
	redRemind(_, {
		type
	}) {
		let params = {
			type,
		};
		return axios.post("/web/station_msg/removeDot", params);
	},

	/**
	 * [编辑|添加]提醒事项列表
	 */
	postSchedule(_, {
		id,
		title,
		content,
		date
	}) {
		let params = {
			title,
			content,
			date,
		};
		if (id) params.id = id;
		return axios.post("/web/reminder/post", params, {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		});
	},

	/**
	 * [删除]提醒事项
	 */
	delSchedule(_, id) {
		return axios.get("/web/reminder/del", {
			params: {
				id,
			},
		});
	},

	/**
	 * 获取当前用户菜单权限
	 */
	getauthority({
		commit
	},uid) {
		axios.get("/web/company/getAccess", {
			params: {
				uid
			}
		})
		.then((res)=>{
			console.log('getAccess',res)
			commit("SET_AUTHORITY", res.data.data)
		})
		.catch((e)=>{
			console.error(e)
		})
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
