import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index.js";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/display.css";

import SingleLayout from "./layout/SinglePage.vue";
import HeaderOnlyLayout from "./layout/HeaderOnly.vue";
import DefaultLayout from "./layout/Default.vue";

Vue.use(ElementUI);

Vue.component("single-layout", SingleLayout);
Vue.component("header-only-layout", HeaderOnlyLayout);
Vue.component("default-layout", DefaultLayout);




Vue.config.productionTip = false;
Vue.config.devtools = true


import * as filters from "./filters/filters";
Object.keys(filters).forEach((key) => {
	Vue.filter(key, filters[key]); //插入过滤器名和对应方法
});



// 全局路由守卫
router.beforeEach((to, from, next) => {
	// console.log(to, from);
	// 权限拦截
	if (to.meta.requestAuth && !store.getters["users/user"].token) {
		next("/login");
		return;
	}
	// 设置侧边导航菜单
	if (to.meta.menu) {
		store.commit("menu/setMenuList", to.meta.menu);
	}
	// 设置header导航菜单激活项
	if (to.meta.headerNavSeleted) {
		store.commit("header/setHeaderNavSelected", to.meta.headerNavSeleted);
	}
	next();
});

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
