<template>
  <div class="bg">
    <div class="container">
      <div class="container_left">
        <el-image :src="logo" fit="contain" style="width: 291px"></el-image>
        <div class="nav_box">
          <div v-for="item in navList" :key="item.name" class="nav_block">
            <div
              :class="{ active: navSeleted == item.path }"
              @click="navClick(item)"
              v-if="role == 1 || header.includes(item.name)"
              style="margin: 0 20px; border-left: 4px solid #2f308a"
            >
              <!-- @click="$router.push({ name: item.path })" -->
              <!-- <div
                            :class="{ active: navSeleted == item.path }"
                            @click="$router.push({ name: item.path })"
                            style="margin: 0 20px"
                        > -->
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <el-dropdown @command="handleCommand">
        <div class="user">
          <div class="user_name">{{ user.account }}</div>
          <el-image
            :src="user.image || require('@/assets/user.jpeg')"
            fit="cover"
            style="width: 34px; height: 34px"
          ></el-image>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="forgetPwd">修改密码</el-dropdown-item>
          <el-dropdown-item command="logout">登出账号</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      host: process.env.VUE_APP_BASE_API,
      navList: [
        {
          name: "工作台",
          path: "Home",
        },
        {
          name: "企业",
          path: "Enterprise",
        },
        {
          name: "员工",
          path: "Employee",
        },
        {
          name: "合同",
          path: "Contract",
        },
        {
          name: "招聘",
          path: "Recruitment",
        },
        {
          name: "发薪",
          path: "Pay",
        },
        {
          name: "审核",
          path: "Attendance",
        },
        // {
        //     name: "管理",
        //     path: "management",
        // },
        {
          name: "灵活用工",
          path: "FlexibleEmployment",
        },
        // {
        //   name: "驾驶舱",
        //   path: "Cockpit",
        // },
      ],
    };
  },
  computed: {
    logo() {
      return this.$store.getters["users/owner"].logo;
    },
    ...mapGetters("header", {
      navSeleted: "headerNavSelected",
    }),
    ...mapGetters("users", {
      user: "user",
      header: "header" || [],
      role: "role",
    }),
  },
  mounted() {
    console.log("test");
  },
  methods: {
    ...mapActions("users", ["logout"]),
    navClick(item) {
      console.log(item);
      this.$router.push({ name: item.path });
    },
    handleCommand(e) {
      console.log(e);
      switch (e) {
        case "forgetPwd":
          this.$router.push({
            name: "ForgetPassword",
          });
          break;

        case "logout":
          this.toLogout();
          break;

        default:
          break;
      }
    },

    toLogout() {
      this.$confirm("要退出登录吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.logout();
        this.$router.push({
          name: "Login",
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  position: relative;
  background-color: #2f308a;
  width: 100%;
  height: 120px;
}

.container {
  position: absolute;
  bottom: 10px;
  padding: 0 30px;
  width: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .container_left {
    display: flex;
    align-items: center;

    .nav_box {
      display: flex;
      margin-left: 100px;

      .nav_block {
        > div {
          position: relative;
          color: #9798d5;
          font-size: 16px;
          // margin: 0 20px;
          cursor: pointer;

          &.active {
            color: #ffffff;

            &::after {
              content: "";
              width: 28px;
              height: 3px;
              border-radius: 2px;
              background-color: #2f308a;
              position: absolute;
              bottom: -12px;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }

  .user {
    display: flex;
    align-items: center;

    .user_name {
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      margin-right: 10px;
    }

    .el-image {
      border-radius: 50%;
    }
  }
}
</style>
