<template>
  <el-container>
    <el-header style="height: 120px; padding: 0;">
      <Header></Header>
    </el-header>
    <el-container>
      <el-aside width="280px">
        <SideBar></SideBar>
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Header from "@/components/Header.vue";
import SideBar from "@/components/SideBar.vue";

export default {
  data() {
    return {};
  },
  components: {
    Header: Header,
    SideBar: SideBar,
  },
};
</script>

<style lang="scss" scoped>
.el-aside {
  background-color: #f8f8f9;
  min-height: calc(100vh - 120px);
}

.el-main {
  padding: 30px;
}
</style>
