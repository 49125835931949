const menus = {
	enterprise_infomation: {
		title: "企业信息",
		icon: "el-icon-office-building",
		children: [
			{
				title: "基础信息",
				name: "Infomations",
			},
			{
				title: "认证信息",
				name: "Authentication",
			},
			{
				title: "企业公告",
				name: "Notice",
			},
			// {
			// 	title: "企业制度",
			// 	name: "System",
			// },
			// {
			// 	title: "开票信息",
			// 	name: "Invoice",
			// },
			// {
			// 	title: "相关设置",
			// 	name: "OtherSettings",
			// },
		],
	},
	enterprise_management: {
		title: "企业管理",
		icon: "el-icon-office-building",
		children: [{
			title: "子公司管理",
			name: "DepartmentList",
		},
		{
			title: "岗位管理",
			name: "StationList",
		},
		{
			title: "角色管理",
			name: "Rolemanagement",
		},
		{
			title: "五险一金",
			name: "InsurancesAndfund",
		},
		{
			title: "劳务管理",
			name: "labourServices",
		},
		],
	},
	approval_management: {
		title: "审批管理",
		icon: "el-icon-document-checked",
		children: [{
			title: "审批表单",
			name: "ApprovalForms",
		},
		{
			title: "审批模板",
			name: "ApprovalTemplateLibrary",
		},
		{
			title: "审批记录",
			name: "ApprovalLog",
		},
			// {
			// 		title: "企微审批记录",
			// 		name: "CorpWechatApprovalLog",
			// }
		],
	},
	attendance_management: {
		title: "考勤管理",
		icon: "el-icon-date",
		children: [{
			title: "考勤明细",
			name: "AttendanceDetails",
		},
		{
			title: "考勤报表",
			name: "AttendanceReport",
		},
		{
			title: "打卡方式",
			name: "PunchWay",
		},
		{
			title: "考勤规则",
			name: "AttendanceRules",
		},
		],
	},
	// piecework_attendance: {
	// 	title: "计件考勤",
	// 	icon: "el-icon-document",
	// 	children: [{
	// 			title: "工序配置",
	// 			name: "ProcessConfiguration",
	// 		},
	// 		{
	// 			title: "员工计件系数",
	// 			name: "EmployeePieceworkFactor",
	// 		},
	// 		{
	// 			title: "员工计件统计",
	// 			name: "EmployeePieceworkStatistics",
	// 		},
	// 	],
	// },
	salary_management: {
		title: "发薪管理",
		src: require("@/assets/icon/faxin.png"),
		children: [
			{
				title: "月考勤管理",
				name: "MonthlyAttendanceManagement",
			},
			{
				title: "月工资统计",
				name: "MonthlyWageStatistics",
			},
			// {
			// 	title: "计薪规则",
			// 	name: "SalarySplit",
			// },
			// {
			// 	title: "薪酬配置",
			// 	name: "SalaryAllocation",
			// },
			{
				title: "薪资发放记录",
				name: "PayrollRecord",
			},
			// {
			// 	title: "月工资表",
			// 	name: "MonthlyPayroll",
			// },
			// {
			// 	title: "拆分规则",
			// 	name: "SalarySplitList",
			// },
			// {
			// 	title: "薪资核算",
			// 	name: "SalaryAudit",
			// },
			// {
			// 	title: "财务报表",
			// 	name: "EmployeeDebit",
			// },
			// {	
			// 	title: "薪资发放",
			// 	name: "SalaryPayment",
			// },
			// {
			// 	title: "薪资报表",
			// 	name: "SalaryReport",
			// },
			// {
			// 	title: "员工借支明细",
			// 	name: "EmployeeDebitDetails",
			// },
			// {
			// 	title: "员工借支扣减",
			// 	name: "EmployeeDebitDeduction",
			// },
		],
	},
	insurance_management: {
		title: "保险管理",
		src: require("@/assets/icon/baoxian.png"),
		children: [{
			title: "保险管理",
			name: "InsuranceManagement",
		},],
	},
	payslip_management: {
		title: "工资条管理",
		src: require("@/assets/icon/gongzitiao.png"),
		children: [{
			title: "发放工资条",
			name: "PaysWages",
		},
		{
			title: "发放记录",
			name: "PaysLogs",
		},
		],
	},
	recruitment_overview: {
		title: "概览",
		src: require("@/assets/icon/overview.png"),
		children: [{
			title: "概览",
			name: "RecruitmentOverview",
		},],
	},
	talents_intelligent_recommendation: {
		title: "人才智能推荐",
		src: require("@/assets/icon/rencai.png"),
		children: [{
			title: "人才智能推荐",
			name: "",
		},],
	},
	recruit_workers: {
		title: "招聘人员",
		src: require("@/assets/icon/zhaopinrenyuan.png"),
		children: [{
			title: "招聘中",
			name: "Recruiting",
		},
		// {
		// 	title: "已入职",
		// 	name: "Recruited",
		// },
		{
			title: "已淘汰",
			name: "Eliminated",
		},
			// {
			// 	title: "人才库",
			// 	name: "TalentPool",
			// },
		],
	},
	recruitment_management: {
		title: "招聘管理",
		src: require("@/assets/icon/zhaopinguanli.png"),
		children: [{
			title: "招聘职位",
			name: "RecruitmentPost",
		},
		{
			title: "招聘团队",
			name: "RecruitmentTeam",
		},
		],
	},
	employee_information: {
		title: "员工信息",
		src: require("@/assets/icon/yuangongxinxi.png"),
		children: [{
			title: "员工花名册",
			name: "EmployeeRoster",
		},
		// {
		// 	title: "员工档案",
		// 	name: "EmployeeFiles",
		// },
		// {
		// 	title: "员工评级",
		// 	name: "EmployeeRating",
		// },
		{
			title: "入职管理",
			name: "EntryManagement",
		},
		{
			title: "转正管理",
			name: "RegularManagement",
		},
		{
			title: "离职管理",
			name: "LeaveManagement",
		},
		{
			title: "白条审核",
			name: "whiteNoteReview",
		},
		{
			title: "调岗管理",
			name: "employeePostTransfer",
		},
		{
			title: "借岗管理",
			name: "employeeBorrow",
		}


		],
	},
	employee_relations: {
		title: "员工申请",
		src: require("@/assets/icon/yuangongguanxi.png"),
		// children: [{
		// 		title: "入职管理",
		// 		name: "EntryManagement",
		// 	},
		// 	{
		// 		title: "转正管理",
		// 		name: "RegularManagement",
		// 	},
		// 	{
		// 		title: "离职管理",
		// 		name: "LeaveManagement",
		// 	},
		// ],
		children: [{
			title: "申请表单",
			name: "applicationForm",
		},
		{
			title: "申请记录",
			name: "applicationRecord",
		}
		],
	},
	contract_management: {
		title: "合同管理",
		src: require("@/assets/icon/hetongguanli.png"),
		children: [{
			title: "标准合同列表",
			name: "StandardContractsList",
		},
		// {
		// 	title: "签署记录",
		// 	name: "SignatureRecord",
		// },

		{
			title: "员工合同信息",
			name: "EmployeeContractInformation",
		},
			// {
			// 	title: "企业合同信息",
			// 	name: "EnterPriseContractInformation"
			// },
			// {
			// 	title: "定制合同列表",
			// 	name: "CustomContractList",
			// },

		],
	},
	platform_services: {
		title: "平台服务",
		src: require("@/assets/icon/pingtaifuwu.png"),
		children: [{
			title: "法律顾问",
			name: "LegalAdviser",
		},
		{
			title: "合同模板",
			name: "ContractTemplate",
		},
		],
	},
	labor_demand: {
		title: "用工需求",
		src: require("@/assets/icon/yonggongxuqiu.png"),
		children: [{
			title: "需求列表",
			name: "DemandList",
		},
		{
			title: "过往需求",
			name: "BeforeDemand",
		},
		],
	},
	// employment_order: {
	// title: "用工订单",
	// src: require("@/assets/icon/yonggongdingdan.png"),
	// children: [
	// 	{
	// 	title: "订单列表",
	// 	name: "OrderList",
	// },
	// {
	// 	title: "异常订单",
	// 	name: "AbnormalOrder",
	// },
	// ],
	// },
	serviceMarket: {
		title: "服务市场",
		src: require("@/assets/icon/fuwushichang.png"),
		children: [{
			title: "平台服务",
			name: "PlatformServices",
		},
		{
			title: "服务超市",
			name: "ServiceSupermarket",
		},
		],
	},
};

const state = {
	menuList: [],
};

// 定义 getters
var getters = {
	getMenu(state) {
		return state.menuList;
	},
};

const actions = {};

const mutations = {
	/**
	 * @param { [...string,[...number]] } params 约定params为任意字符串与最后一位为正整数数组构成的数组
	 */
	setMenuList(state, params) { //
		let list = [];
		// console.log('JSON.parse(localStorage.getItem("authority")).first_nav',JSON.parse(localStorage.getItem("authority")))
		let role = JSON.parse(localStorage.getItem("user")).role
		// 判断如果值为null则不进行执行
		if (!JSON.parse(localStorage.getItem("authority"))) {
			localStorage.setItem("authority", JSON.stringify({}))
			return
		}

		let whitelist1 = JSON.parse(localStorage.getItem("authority")).first_nav
		console.log("whitelist1", whitelist1, role)
		params.forEach((item, index) => {
			if (index == params.length - 1) list.push(item);//获取当前菜单位置
			else {
				if (role == 1 || whitelist1.indexOf(item) > -1) {
					list.push(menus[item]);
				} else {
					console.log('无权限访问')
				}
				let whitelist = JSON.parse(localStorage.getItem("authority")).second_nav
				console.log("menus[item]", menus[item]);
				menus[item] && menus[item].children.forEach((item1, index) => {
					if (role == 1 || whitelist.includes(item1.name)) {
						menus[item].children[index].auth = true
					}
				})
				// list.push(menus[item]);
			}
		});
		state.menuList = list;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
