/* eslint-disable no-unused-vars */
import axios from "axios";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    /**
     * 获取考勤明细统计
     */
    getAttendanceDetailList(_, { count_type, keyword }) {
        let params = {
            count_type: count_type,
        };
        if (keyword) params.key_word = keyword;
        return axios.get("/web/punchCount/index", {
            params,
        });
    },

    /**
     * 获取打卡方式
     */
    getPunchWayList(_, { page, type, keyword }) {
        let params = {
            page,
            "search[type]": type,
        };
        if (keyword) params.keywords = keyword;
        return axios.get("/web/punchType/index", {
            params,
        });
    },

    /**
     * 删除打卡方式
     */
    delPunchWay(_, { id, type }) {
        return axios.get("/web/punchType/del", {
            params: {
                id,
                type,
            },
        });
    },

    /**
     * 考勤机打卡方式
     */
    postMachinePunchWay(_, { id, name, sn_code, serial_number, proxy_url }) {
        let params = {
            type: 1,
            name,
            sn_code,
            serial_number,
            proxy_url,
        };
        if (id) params.id = id;
        return axios.post("/web/punchType/post", params, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    },

    /**
     * 小程序打卡方式
     */
    postMiniProgramPunchWay(_, { id, address, lat, lng, punch_range }) {
        let params = {
            type: 2,
            address,
            lat,
            lng,
            punch_range,
        };
        if (id) params.id = id;
        return axios.post("/web/punchType/post", params, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    },

    /**
     * 获取企微打卡
     */
    getQwPunchWay() {
        return axios.get("/web/Company/getWechatConfig", {
            params: {
                type: "check",
            },
        });
    },

    /**
     * 设置企微打卡
     */
    postQwPunchWay(_, { id, wechat_work_id, app_secret }) {
        let params = {
            type: "check",
            wechat_work_id,
            app_secret,
        };
        if (id) params.id = id;
        return axios.post("/web/Company/setWechatConfig", params, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    },

    /**
     * 获取企微审批
     */
    getQwApproval() {
        return axios.get("/web/Company/getWechatConfig", {
            params: {
                type: "shenpi",
            },
        });
    },

    /**
     * 设置企微审批
     */
    postQwApproval(_, { id, wechat_work_id, app_secret }) {
        let params = {
            type: "shenpi",
            wechat_work_id,
            app_secret,
        };
        if (id) params.id = id;
        return axios.post("/web/Company/setWechatConfig", params, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    },

    /**
     * 获取打卡规则列表
     */
    getPunchRulesList(_, { page }) {
        return axios.get("/web/punchRule/index", {
            params: {
                page,
            },
        });
    },

    /**
     * 新增打卡规则
     */
    postPunchRules(
        _,
        {
            id,
            name,
            work_hours_type,
            day_work_hours_rule_type,
            working_hours,
            deducte_rule,
            work_time_data,
            is_legal_holidays,
            special_date,
            special_staff,
        }
    ) {
        let params = {
            name,
            work_hours_type,
            day_work_hours_rule_type,
            working_hours,
            work_time_data,
            is_legal_holidays,
            special_date,
            special_staff,
        };
        if (id) params.id = id;
        if (deducte_rule) params.deducte_rule = deducte_rule;
        return axios.post("/web/punchRule/save", params, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    },

    /**
     * 获取打卡规则详细
     */
    getPunchRuleDetail(_, { id }) {
        return axios.get("/web/punchRule/detail", {
            params: {
                id,
            },
        });
    },

    /**
     * 获取所有部门员工
     */
    getdepartmentList() {
        return axios.get("/web/company/departmentList");
    },

    /**
     * 获取审批分组
     */
    getApproverCateList(_, { page, size }) {
        let params = {
            page,
            getTotal: 1,
        };
        if (size) params.size = size;
        return axios.get("/web/auditformCate/list", {
            params,
        });
    },

    /**
     * 添加审批模板分类
     */
    postAddApproverCate(_, { title, sort_num }) {
        return axios.post(
            "/web/auditformCate/add",
            {
                title,
                sort_num,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 编辑审批模板分类
     */
    postEditApproverCate(_, { id, title, sort_num }) {
        return axios.post(
            "/web/auditformCate/edit",
            {
                id,
                title,
                sort_num,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 删除审批模板分类
     */
    getDelApproverCate(_, { id }) {
        return axios.get("/web/auditformCate/del", {
            params: {
                id,
            },
        });
    },

    /**
     * 获取审批表单列表
     */
    getApproverList(_, { page, size, cate_id }) {
        let params = {
            page,
            cate_id,
            getTotal: 1,
        };
        if (size) params.size = size;
        return axios.get("/web/auditformTpl/list", {
            params,
        });
    },

    /**
     * 获取审批表单详情
     */
    getApproverDetail(_, { id }) {
        return axios.get("/web/auditformTpl/detail", {
            params: {
                id,
                ifGetListAuditformTplItem: 1,
                ifGetListAuditformFlow: 1,
                ifGetListAuditformPower: 1,
            },
        });
    },

    /**
     * 添加审批表单
     */
    postAddApprover(
        _,
        {
            title,
            sort_num,
            desc,
            cate_id,
            cover_img,
            apply_power,
            auditform_tpl_item,
            auditform_flow,
            auditform_power,
            apply_power_staff_ids
        }
    ) {
        return axios.post(
            "/web/auditformTpl/add",
            {
                title,
                sort_num,
                desc,
                cate_id,
                cover_img,
                auditform_tpl_item,
                apply_power,
                auditform_flow,
                auditform_power,
                apply_power_staff_ids
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 编辑审批表单
     */
    postEditApprover(
        _,
        {
            id,
            title,
            sort_num,
            desc,
            cate_id,
            cover_img,
            apply_power,
            apply_power_staff_ids,
            auditform_tpl_item,
            auditform_flow,
            auditform_power,
        }
    ) {
        return axios.post(
            "/web/auditformTpl/edit",
            {
                id,
                title,
                sort_num,
                desc,
                cate_id,
                cover_img,
                apply_power,
                apply_power_staff_ids,
                auditform_tpl_item,
                auditform_flow,
                auditform_power,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 删除审批表单
     */
    getDelApprover(_, { id }) {
        return axios.get("/web/auditformTpl/del", {
            params: {
                id,
            },
        });
    },

    /**
     * 查询企业微信审批记录
     */
    getWechatApproverLog() {
        return axios.get("/web/auditform/wechat");
    },

    /**
     * 同步企微打卡记录
     */
    getSyncPunchLog(_, { start_time, end_time }) {
        return axios.get("/web/company/getWechatUserPunch", {
            params: {
                start_time,
                end_time,
            },
        });
    },

    /**
     * 同步企微审批记录
     */
    getSyncApprovalLog(_, { start_time, end_time }) {
        return axios.get("/web/company/shenPi", {
            params: {
                start_time,
                end_time,
            },
        });
    },

    /**
     * 公司查询审批记录列表
     */
    getApprovalList(_, { page, keyword }) {
        let params = {
            page,
            admin: 1,
            getTotal: 1,
        };
        if (keyword) params.search = keyword;
        return axios.get("/web/auditform/list", {
            params,
        });
    },

    /**
     * 关闭审批记录
     */
    closeApprovalLog(_, { id }) {
        return axios.get("/web/auditform/close", {
            params: {
                id,
            },
        });
    },

    /**
     * 获取审批模板列表
     */
    getApprovalTemplateList(_, { page, keyword }) {
        let params = {
            page,
            sys_model: 1,
            getTotal: 1,
        };
        if (keyword) params.title = keyword;
        return axios.get("/web/auditformTpl/list", {
            params,
        });
    },

    /**
     * 获取审批模板详情
     */
    getApprovalTemplateDetail(_, { id }) {
        return axios.get("/web/auditformTpl/detail", {
            params: {
                id,
                sys_model: 1,
                ifGetListAuditformTplItem: 1
            },
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
