import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import state from "./state";
import getters from "./getters";
import actions from "./actions";
import users from "./modules/users";
import header from "./modules/header";
import menu from "./modules/menu";
import recruitment from "./modules/recruitment";
import enterprise from "./modules/enterprise";
import attendance from "./modules/attendance";
import flexibleEmployment from "./modules/flexibleEmployment";
import employee from "./modules/employee";
import contract from "./modules/contract";
import pay from "./modules/pay";
import cockpit from "./modules/cockpit";
import permission from "./modules/permission";
import postTransfer from "./modules/postTransfer";

// 导出 store 对象
export default new Vuex.Store({
    state,
    getters,
    actions,
    permission,
    modules: {
        users,
        header,
        menu,
        recruitment,
        enterprise,
        attendance,
        flexibleEmployment,
        employee,
        contract,
        pay,
        cockpit,
        postTransfer
    },
});
