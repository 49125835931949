<template>
	<div class="page">
		<!-- 日程表 -->
		<div class="page_left_box">
			<div class="calendar">
				<el-calendar v-model="date">
					<template v-slot:dateCell="{ date, data }">
						<el-badge is-dot :hidden="!getSchedule(date)" style="height: 30px; width: 90%;">
							<p>{{ data.day.split("-")[2] }}</p>
						</el-badge>
						<p :class="data.isSelected ? 'is-selected' : ''">
							{{ solarDate2lunar(data.day, 1) }}
						</p>
					</template>
				</el-calendar>
			</div>
			<div class="schedule">
				<el-image class="more" :src="require('@/assets/home/more.png')" fit="contain"
					style="width: 22px; height: 4px;"></el-image>
				<div class="date_box">
					<div class="day">{{ date.getDate() }}</div>
					<div class="day_detail">
						<div>
							星期{{
                                ["", "一", "二", "三", "四", "五", "六", "日"][
                                    date.getDay()
                                ]
                            }}
						</div>
						<div>农历{{ solarDate2lunar(date, 2) }}</div>
					</div>
					<el-image :src="require('@/assets/home/add.png')" fit="contain"
						style="width: 29px; height: 29px; cursor: pointer;" @click="showAddDialog"></el-image>
				</div>
				<div class="text1">
					{{
                        getSchedule(date) && getSchedule(date).length > 0
                            ? getSchedule(date)[0].title
                            : "当日无事项"
                    }}
				</div>
				<div class="text2">
					{{
                        getSchedule(date) && getSchedule(date).length > 0
                            ? getSchedule(date)[0].content
                            : "成年人的世界里没有容易二字，有时候拼命往前跑，不过是为了留在原地。人生需要一些看似无用的东西，愿你也是一个拿的起放得下的人，余生坦荡微笑，自在洒脱。"
                    }}
				</div>
				<div class="notice" v-if="getSchedule(date) && getSchedule(date).length > 1">
					今天还有{{
                        getSchedule(date) && getSchedule(date).length
                    }}个事项
				</div>
				<div class="custom_button no_select" @click="dialogTableVisible = true">
					查看更多事项<i class="el-icon-d-arrow-right"></i>
				</div>
			</div>
		</div>
		<!-- 数据 -->
		<div class="datas_box">
			<!-- 1 -->
			<div class="staff_box">
				<el-image :src="require('@/assets/home/bg.png')" fit="fill"
					style="width: 456px; height: 100px; display: block;"></el-image>
				<div class="company">{{indexData.name}}</div>
				<div class="staff_data">
					<!-- <div @click="$router.push({name: 'EmployeeRoster'})"> -->
					<div>
						<div>{{ indexData.staff_num }}</div>
						<div>员工数量</div>
					</div>
					<!-- <div @click="$router.push({name: 'RecruitmentPost'})"> -->
					<div>
						<div>{{ indexData.job_num }}</div>
						<div>在招岗位</div>
					</div>
					<div>
						<div>{{ indexData.talent_matching_num }}</div>
						<div>人才匹配</div>
					</div>
				</div>
			</div>

			<!-- 2 -->
			<div class="pending_box">
				<div class="title">待处理事项</div>
				<el-divider></el-divider>
				<div class="pending_data">
					<div>
						<div>{{ indexData.staff_status3_num }}</div>
						<div>待入职</div>
					</div>
					<div>
						<div>{{ indexData.contract_nosign_num }}</div>
						<div>待签合同</div>
					</div>
					<div>
						<div>{{ indexData.contract_expired_num }}</div>
						<div>合同到期</div>
					</div>
					<div>
						<div>{{ indexData.staff_status4_num }}</div>
						<div>离职人员</div>
					</div>
					<!-- <div @click="$router.push({name: 'EmployeeFiles'})">
						<div>{{ indexData.staff_file_num }}</div>
						<div>档案待完善</div>
					</div> -->
					<div>
						<div>{{ indexData.staff_file_num }}</div>
						<div>档案待完善</div>
					</div>
					<div>
						<div>{{ indexData.staff_status2 }}</div>
						<div>待转正</div>
					</div>
				</div>
			</div>

			<!-- 3 -->
			<!-- <div class="qentry_box">
				<div class="title">快速入口</div>
				<el-divider></el-divider>
				<div class="qentry_data">
					<div @click="$router.push({name: 'EntryManagement'})">
						<el-image :src="require('@/assets/home/entry.png')" fit="contain"
							style="width: 30px; height: 30px;"></el-image>
						<div>入职管理</div>
					</div>
					<div @click="$router.push({name: 'LeaveManagement'})">
						<el-image :src="require('@/assets/home/exit.png')" fit="contain"
							style="width: 30px; height: 30px;"></el-image>
						<div>离职管理</div>
					</div>
					<div @click="$router.push({name: 'AttendanceDetails'})">
						<el-image :src="require('@/assets/home/attendance.png')" fit="contain"
							style="width: 30px; height: 30px;"></el-image>
						<div>考勤管理</div>
					</div>
					<div @click="$router.push({name: 'SalarySplit'})">
						<el-image :src="require('@/assets/home/salary.png')" fit="contain"
							style="width: 30px; height: 30px;"></el-image>
						<div>薪酬管理</div>
					</div>
				</div>
			</div> -->
		</div>

		<el-dialog title="事项" :visible.sync="dialogFormVisible">
			<el-form ref="form" :model="form" :rules="rules">
				<el-form-item label="标题" prop="title">
					<el-input v-model="form.title" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="内容" prop="content">
					<el-input type="textarea" v-model="form.content" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="scheduleSubmit">确 定</el-button>
			</div>
		</el-dialog>

		<el-dialog title="当日事项" :visible.sync="dialogTableVisible">
			<el-table :data="getSchedule(date)">
				<el-table-column property="title" label="标题"></el-table-column>
				<el-table-column property="content" label="内容">
					<template slot-scope="scope">
						<div style="white-space: pre-line;">
							{{ scope.row.content }}
						</div>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="120">
					<template slot-scope="scope">
						<el-button @click.native.prevent="editSchedule(scope.row)" type="text" size="small">
							编辑
						</el-button>
						<el-button @click.native.prevent="deleteSchedule(scope.row.id)" type="text" size="small"
							style="color: #e50112;">
							移除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>
		<el-dialog title="提醒信息" :visible.sync="dialogTableVisible2">
			<el-table :data="remindData" @row-click="read">
				<el-table-column property="title" label="类目"></el-table-column>
				<el-table-column property="read_num" fixed="right" label="入口" width="120"></el-table-column>
			</el-table>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapActions
	} from "vuex";

	import calendar from "@/util/calendar";

	export default {
		name: "Home",
		data() {
			return {
				date: new Date(),
				month: this.formatTime(new Date(), "yyyy-MM"),
				indexData: {},
				scheduleMap: {},
				dialogFormVisible: false,
				dialogTableVisible: false,
				dialogTableVisible2: false,
				remindData: [],
				form: {
					title: "",
					content: "",
					date: "",
				},
				rules: {
					title: [{
						required: true,
						message: "请填写标题",
						trigger: "blur",
					}, ],
					content: [{
						required: true,
						message: "请填写内容",
						trigger: "blur",
					}, ],
				},
			};
		},
		created() {
			this.getIndex()
				.then((res) => {
					console.log('home',res);
					this.indexData = res.data.data;
				})
				.catch((e) => {
					console.error(e);
				});

			this.loadScheduleList();
			this.remind();
		},
		watch: {
			date(newVal) {
				if (newVal.getMonth() != new Date(this.month).getMonth()) {
					this.month = this.formatTime(newVal, "yyyy-MM");
					this.loadScheduleList();
				}
			},
		},
		computed: {
			getSchedule() {
				return (date) => {
					return this.scheduleMap[this.formatTime(date, "yyyy-MM-dd")];
				};
			},
		},
		methods: {
			...mapActions("users", [
				"getIndex",
				"getScheduleList",
				"postSchedule",
				"delSchedule",
				"getRemind",
				"redRemind",
			]),

			remind() {
				this.getRemind()
					.then((res) => {
						let remindData = []
						res.data.data.list.map((item) => {
							if (item.read_num > 0) {
								remindData.push(item)
							}
						})
						this.remindData = remindData;
						if(remindData.length>0){
							this.dialogTableVisible2 = true
						}
					})
					.catch((e) => {
						this.$message.error(e.msg);
					});
			},

			read(row) {
				let routeName = ''
				if (row.type == 1) {
					// 工作台的备忘录提醒
					return
				}
				if (row.type == 2) {
					// 新入职员工提醒
					routeName = 'EntryManagement'
				}
				if (row.type == 3) {
					// 合同未签订提醒
					routeName = 'EmployeeContractInformation'
				}
				if (row.type == 4) {
					// 合同要到期提醒
					routeName = 'EmployeeContractInformation'
				}
				if (row.type == 5) {
					// 离职申请提醒
					routeName = 'LeaveManagement'
				}
				if (row.type == 6) {
					// 档案待完善提醒
					routeName = 'EmployeeFiles'
				}
				if (row.type == 7) {
					// 转正提醒
					routeName = 'RegularManagement'
				}
				this.redRemind({
						type: row.type
					})
					.then((res) => {
						console.log(res);
						this.$router.push({
							name: routeName
						})
					})
					.catch((e) => {
						this.$message.error(e.msg);
					});

			},

			loadScheduleList() {
				let loading = this.$loading();
				this.getScheduleList({
						month: this.month
					})
					.then((res) => {
						console.log(res);
						let scheduleMap = {};
						res.data.data.list.forEach((item) => {
							if (scheduleMap[item.date]) {
								scheduleMap[item.date].push(item);
							} else {
								scheduleMap[item.date] = [item];
							}
						});
						this.scheduleMap = scheduleMap;
						loading.close();
					})
					.catch((e) => {
						loading.close();
						console.error(e);
						this.$message.error(e.msg);
					});
			},

			formatTime(date, format) {
				return this.$options.filters.formatTime(date, format);
			},

			showAddDialog() {
				this.form = {
					date: this.formatTime(this.date, "yyyy-MM-dd"),
					title: "",
					content: "",
				};
				this.dialogFormVisible = true;
			},

			editSchedule(item) {
				this.form = {
					id: item.id,
					date: this.formatTime(this.date, "yyyy-MM-dd"),
					title: item.title,
					content: item.content,
				};
				this.dialogFormVisible = true;
			},

			deleteSchedule(id) {
				this.$confirm("此操作将永久删除该条目, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					let loading = this.$loading();
					this.delSchedule(id)
						.then((res) => {
							console.log(res);
							loading.close();
							this.$message({
								type: "success",
								message: res.data.msg,
							});
							this.loadScheduleList();
						})
						.catch((e) => {
							console.log(e);
							loading.close();
							this.$message.error("信息提交失败");
						});
				});
			},

			scheduleSubmit() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						console.log(this.form);
						let loading = this.$loading();
						this.postSchedule(this.form)
							.then((res) => {
								this.dialogFormVisible = false;
								console.log(res);
								loading.close();
								this.$message.success("信息提交成功");
								this.loadScheduleList();
							})
							.catch((e) => {
								console.log(e);
								loading.close();
								this.$message.error("信息提交失败");
							});
					} else {
						this.$notify.error({
							title: "提交失败",
							message: "请先完整填写表单",
						});
						return false;
					}
				});
			},

			// 转农历
			solarDate2lunar(solarDate, type) {
				let solar, lunar;
				switch (type) {
					case 1:
						solar = solarDate.split("-");
						lunar = calendar.solar2lunar(solar[0], solar[1], solar[2]);
						// return solar[1] + '-' + solar[2] + '\n' + lunar.IMonthCn + lunar.IDayCn
						return lunar.IDayCn;

					case 2:
						lunar = calendar.solar2lunar(
							solarDate.getFullYear(),
							solarDate.getMonth() + 1,
							solarDate.getDate()
						);
						return lunar.IMonthCn + lunar.IDayCn;
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.page {
		min-height: calc(100vh - 160px);
		background-color: #fafafc;
		display: flex;
		padding: 20px 0;
	}

	.page_left_box {
		background-color: #fff;
		width: 1380px;
		height: 743px;
		display: flex;
		position: relative;

		.calendar {
			width: 786px;
			height: 587px;
			padding: 50px 35px;

			.is-selected {
				color: #1989fa;
			}
		}

		.schedule {
			width: 337px;
			padding: 95px 0;

			.more {
				position: absolute;
				right: 47px;
				top: 60px;
			}

			.date_box {
				display: flex;
				color: #1c2438;
				position: relative;

				.day {
					font-size: 60px;
					font-weight: bold;
				}

				.day_detail {
					font-size: 14px;
					position: relative;
					top: 26px;
					margin-left: 14px;
				}

				>.el-image {
					position: absolute;
					right: 0;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}

			.text1 {
				color: #4c71f0;
				font-size: 14px;
				margin-top: 40px;
			}

			.text2 {
				color: #656a70;
				font-size: 14px;
				margin-top: 20px;
				white-space: pre-line;
				overflow: auto;
				max-height: 236px;
			}

			.notice {
				color: #e50112;
				font-size: 14px;
				margin-top: 50px;
			}

			.custom_button {
				width: 346px;
				height: 48px;
				line-height: 48px;
				font-size: 12px;
				position: absolute;
				bottom: 100px;
			}
		}
	}

	.datas_box {
		height: 743px;
		width: 456px;
		margin-left: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.staff_box {
			background-color: #fff;
			height: 220px;
			position: relative;

			.company {
				position: absolute;
				top: 0;
				font-size: 24px;
				color: white;
				width: 456px;
				height: 100px;
				line-height: 100px;
				text-align: center;
			}

			.staff_data {
				display: flex;
				justify-content: space-around;
				align-items: center;
				height: 220-100px;

				>div {
					text-align: center;
					cursor: pointer;

					>div:nth-child(1) {
						font-size: 20px;
						color: #1c2438;
					}

					>div:nth-child(2) {
						font-size: 14px;
						color: #656a70;
						margin-top: 12px;
					}
				}
			}
		}

		.pending_box {
			padding: 30px;
			background-color: #fff;

			.title {
				font-size: 16px;
				font-weight: bold;
				color: #1c2438;
			}

			.pending_data {
				display: flex;
				flex-wrap: wrap;

				>div {
					width: 33.33%;
					height: 80px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					cursor: pointer;

					>div:nth-child(1) {
						font-size: 20px;
						color: #1c2438;
					}

					>div:nth-child(2) {
						font-size: 14px;
						color: #656a70;
						margin-top: 12px;
					}
				}
			}
		}

		.qentry_box {
			padding: 30px;
			background-color: #fff;

			.title {
				font-size: 16px;
				font-weight: bold;
				color: #1c2438;
			}

			.qentry_data {
				display: flex;

				>div {
					flex: 1;
					height: 60px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					cursor: pointer;

					>div:nth-child(1) {
						font-size: 20px;
						color: #1c2438;
					}

					>div:nth-child(2) {
						font-size: 12px;
						color: #656a70;
						margin-top: 12px;
					}
				}
			}
		}
	}
</style>
