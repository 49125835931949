import axios from "axios";
import { jsonp } from "vue-jsonp";

const actions = {
    /**
     * 上传文件
     */
    uploadFile(_, file) {
        console.log(file);
        let form = new FormData();
        if (file.file && file.type) {
            form.append("file", file.file);
            if (file.type) form.append("type", file.type);
        } else {
            form.append("file", file);
        }
        return axios.post("/web/upload/index?no_sign=1", form, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },

    /**
     * 获取客户端ip定位
     */
    getPosition({ state }) {
        return jsonp("https://apis.map.qq.com/ws/location/v1/ip", {
            key: state.map_key,
            output: "jsonp",
        });
    },

    /**
     * 获取地图搜索建议
     */
    getSuggestion({ state }, { region, keyword }) {
        return jsonp("https://apis.map.qq.com/ws/place/v1/suggestion", {
            key: state.map_key,
            region,
            keyword,
            output: "jsonp",
        });
    },

    /**
     * 逆地址解析
     */
    getGeocoder({ state }, { location }) {
        return jsonp("https://apis.map.qq.com/ws/geocoder/v1/", {
            key: state.map_key,
            location,
            output: "jsonp",
        });
    },

    /**
     * 获取合伙人logo
     */
    getLogo({ state, commit }, { owner_id }) {
        if (state.logo[owner_id]) return state.logo[owner_id];
        axios
            .get("", {
                params: {
                    owner_id,
                },
            })
            .then((res) => {
                console.log(res);
                commit.SET_LOGO(res);
            })
            .catch((e) => {
                console.error(e);
            });
    },
};
// 最后导出
export default actions;
