const state = {
  headerNavSelected: 'Home',
};

// 定义 getters
var getters = {
  headerNavSelected(state) {
    return state.headerNavSelected;
  },
};

const actions = {};

const mutations = {
  setHeaderNavSelected(state, index) {
    state.headerNavSelected = index;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
