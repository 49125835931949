// 调岗
import axios from "axios";

const state = {};

const getters = {};

const mutations = {};
const actions = {

  // 调岗列表 page  size
  getTransferList(_, params) {
    return axios.get("/web/staff/transferList", {
      params,
    });
  },
  //记录详情
  getTransferDetail(_, params) {
    return axios.get("/web/staff/transferDetail", {
      params,
    });
  },
  // 撤销调岗
  revokeTransfer(_, params) {
    return axios.get("/web/staff/revokeTransfer", {
      params,
    });
  },
  // 删除调岗记录
  delTransfer(_, params) {
    return axios.get("/web/staff/delTransfer", {
      params,
    });
  },
  //调岗导出 
  exportTransfer(_, params) {
    let url =
      "https://hrv1-end.yx2.qushiyun.com/" + params.url;
    var xhr = new XMLHttpRequest(); //定义http请求对象
    xhr.open("GET", url, true);
    let token = JSON.parse(localStorage.getItem("user")).token;
    xhr.setRequestHeader("Authorization", "Bearer " + token);
    xhr.send();
    xhr.responseType = "blob"; // 返回类型blob
    xhr.onload = function () {
      // 定义请求完成的处理函数，请求前也可以增加加载框/禁用下载按钮逻辑
      if (this.status === 200) {
        var blob = this.response;
        console.log(this.response);
        var reader = new FileReader();
        reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
        reader.onload = function (e) {
          var a = document.createElement("a");
          a.download = params.name; //自定义下载文件名称
          a.href = e.target.result;
          document.body.appendChild(a); // 修复firefox中无法触发click
          a.click();
        };
      }
    };
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};