/* eslint-disable no-unused-vars */
import axios from "axios";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    /**
     * 获取标准合同列表
     */
    getStandardContractList(_, { page, size, keywords }) {
        let params = {
            page,
            ifGetCountUse: 1,
            myCollect: 1,
        };
        if (size) params.size = size;
        if (keywords) params.keywords = keywords;
        return axios.get("/contract/fdd_template/list", {
            params,
        });
    },

    /**
     * 获取定制合同列表
     */
    getCustomContractList(_, { page, size, keywords }) {
        let params = {
            page,
            ifGetCountUse: 1,
            onlyGetMyUpload: 1,
        };
        if (size) params.size = size;
        if (keywords) params.keywords = keywords;
        return axios.get("/contract/fdd_template/list", {
            params,
        });
    },

    /**
     * 获取合同模板列表
     */
    getContractTemplateList(_, { page, size, keywords }) {
        let params = {
            page,
            ifGetCountUse: 1,
            upload_fdd_user_id: 0,
        };
        if (size) params.size = size;
        if (keywords) params.keywords = keywords;
        return axios.get("/contract/fdd_template/list", {
            params,
        });
    },

    /**
     * 获取员工合同信息列表
     */
    getEmployeeContractList(_, { page, size, keywords }) {
        let params = {
            page,
            ifGetInfoFddUser: 1,
            ifGetInfoSignLog: 1,
            ifGetInfoOther: 1,
            party_b_fdd_type: 1,
            getTotal: 1,
        };
        if (size) params.size = size;
        if (keywords) params.keywords = keywords;
        return axios.get("/contract/fdd_contract/list1", {
            params,
        });
    },

    /**
     * 获取企业合同信息列表
     */
    getEnterpriseContractList(_, { page, size, keywords, type }) {
        let params = {
            page,
            ifGetInfoFddUser: 1,
            ifGetInfoSignLog: 1,
            ifGetInfoOther: 1,
            getTotal: 1,
        };
        if (type == 1) params.party_b_fdd_type = 2;
        if (type == 2) params.my_party_b = 1;
        if (size) params.size = size;
        if (keywords) params.keywords = keywords;
        return axios.get("/contract/fdd_contract/list1", {
            params,
        });
    },

    /**
     * 添加定制合同
     */
    addCustomContract(_, { title, contractPdfUrl }) {
        return axios.post(
            "/contract/fdd_template/add",
            {
                title,
                contractPdfUrl,
                // template_params_json,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 加载fdd员工列表
     */
    getFddEmployeeList(_, { page, size, keywords }) {
        let params = {
            page,
            ifGetInfoFddUser: 1,
        };
        if (size) params.size = size;
        if (keywords) params.keywords = keywords;
        return axios.get("/web/staff/list", {
            params,
        });
    },

    /**
     * 加载子公司列表
     */
    getSubsidiaryList(_, { page, size, keywords }) {
        let params = {
            page,
        };
        if (size) params.size = size;
        if (keywords) params.name = keywords;
        return axios.get("/web/company/list", {
            params,
        });
    },

    /**
     * 生成合同
     */
    postGenerateContract(
        _,
        { template_id, parameter_map, doc_title, party_b_fdd_userid, doc_url }
    ) {
        return axios.post(
            "/contract/fdd_contract/add",
            {
                template_id,
                parameter_map,
                doc_title,
                party_b_fdd_userid,
                doc_url,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 使用合同模板
     */
    useFddContractTemplate(_, { fdd_template_id }) {
        return axios.get("/contract/company_contract_tpl/add", {
            params: {
                fdd_template_id,
            },
        });
    },

    /**
     * 标准模板批量签署
     */
    postBatchSign(_, { template_ids, party_b_fdd_userids }) {
        return axios.post(
            "/contract/fdd_contract/batchadd",
            {
                template_ids,
                party_b_fdd_userids,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 获取过往咨询
     */
    getLegalAdviserList(_, { page, size }) {
        let params = {
            page,
            getTotal: 1,
        };
        if (size) params.size = size;
        return axios.get("/web/contract_consult/list", {
            params,
        });
    },

    /**
     * 添加法律顾问
     */
    postAddLegalAdviser(_, { title, ori_file_url, remark }) {
        return axios.post(
            "/web/contract_consult/add",
            {
                title,
                ori_file_url,
                remark,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
