/* eslint-disable no-unused-vars */
import axios from "axios";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    /**
     * 招聘中列表
     */
    getRecruitingList(_, { page, keywords, size } = {}) {
        let params = {
            page,
            type: 1,
            size
        };
        if (keywords) params.keywords = keywords;
        return axios.get("/web/jobResume/list", {
            params,
        });
    },

    getRecruiedList(_, { page, keywords } = {}) {
        let params = {
            page,
            type: 2,
        };
        if (keywords) params.keywords = keywords;
        return axios.get("/web/jobResume/list", {
            params,
        });
    },

    getEliminatedList(_, { page, keywords } = {}) {
        let params = {
            page,
            type: 3,
        };
        if (keywords) params.keywords = keywords;
        return axios.get("/web/jobResume/list", {
            params,
        });
    },

    /**
     * 获取招聘统计
     */
    getRecruitmentStatistics() {
        return axios.get("/web/jobResume/getStatistics");
    },

    /**
     * 招聘岗位列表
     */
    getJobList(_, { page, keywords } = {}) {
        let params = {
            page,
        };
        if (keywords) params.keywords = keywords;
        return axios.get("/web/job/index", {
            params,
        });
    },

    /**
     * 招聘岗位详情
     */
    getJobDetail(_, { id }) {
        return axios.get("/web/job/detail", {
            params: {
                id,
            },
        });
    },

    /**
     * 招聘岗位-开启/停止
     */
    getJobDisable(_, { id, isStop }) {
        return axios.get("/web/job/check", {
            params: {
                id,
                status: isStop ? 4 : 1,
            },
        });
    },

    /**
     * 招聘岗位添加
     */
    postAddJob(
        _,
        {
            name,
            need_num,
            experience,
            degree,
            content,
            job_type,
            recruit_time,
            salary_min,
            salary_max,
            status,
            welfare_ids,
            cover_image,
            province, city, area
        }
    ) {
        return axios.post(
            "/web/job/addJob",
            {
                name,
                need_num,
                experience,
                degree,
                content,
                job_type,
                recruit_time,
                salary_min,
                salary_max,
                status,
                welfare_ids,
                cover_image,
                province, city, area
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 招聘岗位编辑
     */
    postEditJob(
        _,
        {
            id,
            name,
            need_num,
            experience,
            degree,
            content,
            job_type,
            recruit_time,
            salary_min,
            salary_max,
            status,
            welfare_ids,
            cover_image,
            province, city, area
        }
    ) {
        return axios.post(
            "/web/job/editJob",
            {
                id,
                name,
                need_num,
                experience,
                degree,
                content,
                job_type,
                recruit_time,
                salary_min,
                salary_max,
                status,
                welfare_ids,
                cover_image,
                province, city, area
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 审核
     */
    getAduit(_, { id, status }) {
        return axios.get("/web/jobResume/aduit", {
            params: {
                id,
                status,
            },
        });
    },

    /**
     * 人才库列表
     */
    getjobResumeList(_, { page, keywords } = {}) {
        let params = {
            page,
        };
        if (keywords) params.keywords = keywords;
        return axios.get("/web/jobResume/list", {
            params,
        });
    },

    /**
     * 获取工作经验，学历下拉菜单
     */
    getExperienceDegreeData() {
        return axios.get("/web/config/allData", {
            params: {
                types: "experience,degree",
            },
        });
    },

    /**
     * 获取公司福利
     */

    getjobwelfare() {
        return axios.get("/web/job/getWelfareList", {
            params: {
                getAll: 1,
            }
        })
    },

    /**
     * 获取招聘团队列表
     */
    getJobTeam(_, { page, size, type, keywords }) {
        let params = {
            page,
            size,
            "search[type]": type,
        };
        if (keywords) params.keywords = keywords;
        return axios.get("/web/jobTeam/index", {
            params,
        });
    },

    /**
     * 添加招聘团队成员
     */
    addJobTeammate(_, { staff_id, job_ids, type }) {
        return axios.post(
            "/web/jobTeam/save",
            {
                staff_id,
                job_ids,
                type,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 编辑招聘团队成员
     */
    editJobTeammate(_, { id, staff_id, job_ids, type }) {
        return axios.post(
            "/web/jobTeam/save",
            {
                id,
                staff_id,
                job_ids,
                type,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 删除招聘团队
     */
    delJobTeammate(_, { id }) {
        return axios.get("/web/jobTeam/del", {
            params: {
                id,
            },
        });
    },

    /**
     * 邀请面试
     */
    postInterview(_, { id, interview_time, hr_id, interview_address }) {
        return axios.post(
            "/web/jobResume/interview",
            {
                id,
                interview_time,
                hr_id,
                interview_address,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
