/* eslint-disable no-unused-vars */
import axios from "axios";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    /**
     * 获取企业信息
     */
    getEnterpriseInfo() {
        return axios.get("/web/company/info");
    },

    /**
     * 编辑企业信息
     */
    postEnterpriseInfo(
        _,
        {
            image,
            name,
            social_code,
            industry,
            province,
            city,
            area,
            address,
            staff_num,
            contact_people,
            contact_mobile,
            post_code
        }
    ) {
        return axios.post(
            "/web/company/info",
            {
                image,
                name,
                social_code,
                industry,
                province,
                city,
                area,
                address,
                staff_num,
                contact_people,
                contact_mobile,
                post_code
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 获取企业认证信息
     */
    getEnterpriseAuthInfo() {
        return axios.get("/web/company/getAuth");
    },

    /**
     * 上传营业执照识别
     */
    uploadLicense(_, file) {
        return new Promise((resolve, reject) => {
            let form = new FormData();
            form.append("file", file);
            return axios
                .post("/web/config/aiScan?no_sign=1", form, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },

    /**
     * 企业认证
     */
    postEnterpriseAuth(
        _,
        {
            name, // 企业名称
            license, // 营业执照上传识别 调用上传图片返回链接
            tax_number, // 税号
            license_number, // 证照编号
            company_type, // 公司类型
            incorporation_date, // 成立日期 yyyy-MM-dd
            registered_capital, // 万
            legal_person, // 	公司法人
            certificate_type, // 证件类型 身份证 护照
            certificate, // 证件号
            actual_legal_person, // 实际控制人
            actual_legal_person_idcard, // 实际控制人身份证
            business_term, // 营业期限
            address, // 地址
            business, // 经营范围
            social_code, // 社会统一信用代码
        }
    ) {
        return axios.post(
            "/web/company/postAuth",
            {
                name,
                license,
                tax_number,
                license_number,
                company_type,
                incorporation_date,
                registered_capital,
                legal_person,
                certificate_type,
                certificate,
                actual_legal_person,
                actual_legal_person_idcard,
                business_term,
                address,
                business,
                social_code,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 获取企业公告列表
     */
    getEnterpriseNoticeList(_, { page, state, keywords }) {
        let params = {
            page,
            "search[type]": 1,
        };
        if (state) params["search[state]"] = state;
        if (keywords) params.keywords = keywords;
        return axios.get("/web/notice/index", {
            params,
        });
    },

    // 公告列表模糊刷新
    getEnterpriseNoticeLists(_, { page, state, keywords }) {
        let params = {
            page,
            "search[state]": state,
        };
        console.log('pramamsState', params.state);
        if (state) params["search[state]"] = state;
        if (keywords) params.keywords = keywords;
        return axios.get("/web/notice/index", {
            params,
        });
    },

    /**
     * 企业公告分类新增/编辑
     */
    postEnterpriseNoticeCate(_, { id, cate_name }) {
        let data = {
            cate_name,
        };
        if (id) data.id = id;
        return axios.post("/web/noticeCate/post", data, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    },

    /**
     * 添加企业公告
     */
    postEnterpriseNotice(_, params) {
        return axios.post(
            "/web/notice/post",
            { ...params, type: 1 },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 删除企业公告/制度
     */
    delEnterpriseNotice(_, { id }) {
        return axios.get("/web/notice/del", {
            params: { id },
        });
    },

    /**
     * 获取企业制度分类列表
     */
    getEnterpriseSystemCateList() {
        return axios.get("/web/noticeCate/index");
    },

    /**
     * 获取企业制度列表
     */
    getEnterpriseSystemList(_, { page, cate_id, state, keywords }) {
        let params = {
            page,
            "search[type]": 2,
            "search[cate_id]": cate_id,
        };
        if (state) params["search[state]"] = state;
        if (keywords) params.keywords = keywords;
        return axios.get("/web/notice/index", {
            params,
        });
    },

    /**
     * 添加企业制度
     */
    postEnterpriseSystem(_, { cate_id, title, content, state }) {
        return axios.post(
            "/web/notice/post",
            {
                cate_id,
                title,
                type: 2,
                content,
                state,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 获取企业开票信息
     */
    getEnterpriseInvoiceInfo() {
        return axios.get("/web/invoice/info");
    },

    /**
     * 修改企业开票信息
     */
    postEnterpriseInvoiceInfo(
        _,
        { company_name, social_code, bank_name, bank_account, contact, address }
    ) {
        return axios.post(
            "/web/invoice/info",
            {
                company_name,
                social_code,
                bank_name,
                bank_account,
                contact,
                address,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 获取企业开票二维码
     */
    getEnterpriseInvoiceQrcode() {
        return axios.get("/web/invoice/qrcode");
    },

    /**
     * 获取企业相关设置
     */
    getEnterpriseOtherSetting() {
        return axios.get("/web/companySetting/detail");
    },

    /**
     * 更新企业相关设置
     */
    postEnterpriseOtherSetting(
        _,
        {
            salary_overtime_base,
            salary_header,
            salary_pre_header,
            punch_late,
            punch_absenteeism,
            contract_notify_timelong,
        }
    ) {
        return axios.post(
            "/web/companySetting/detail",
            {
                salary_overtime_base,
                salary_header,
                salary_pre_header,
                punch_late,
                punch_absenteeism,
                contract_notify_timelong: contract_notify_timelong * 60,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 获取白条信息列表
     */
    getWhiteList(_, { page, size }) {
        return axios.get("/web/white/list", {
            params: {
                page,
                size,
            },
        });
    },
    /**
     * 审核白条
     */
    examinedWhite(_, { id, type, remark }) {
        return axios.get("/web/white/edit", {
            params: {
                id,
                type,
                remark
            },
        });
    },


    /**
     * 获取部门列表
     */
    getDepartmentList(_, { page, size, name }) {
        return axios.get("/web/department/list", {
            params: {
                label: 1,
                page,
                size,
                keywords: name
            },
        });
    },

    /**
     * 添加部门
     */
    addDepartment(_, { name }) {
        return axios.post(
            "/web/department/add",
            {
                name,
                label: 1,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 编辑部门
     */
    editDepartment(_, { name, id }) {
        return axios.post(
            "/web/department/edit",
            {
                id,
                name,
                label: 1,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 获取职务列表
     */
    getPostList(_, params) {
        return axios.get("/web/department/list", {
            params: {
                ...params,
                label: 2,
            },
        });
    },

    /**
     * 添加职务
     */
    addPost(_, { name, pid }) {
        return axios.post(
            "/web/department/add",
            {
                name,
                pid,
                label: 2,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 编辑职务
     */
    editPost(_, { name, pid, id }) {
        return axios.post(
            "/web/department/edit",
            {
                id,
                name,
                pid,
                label: 2,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 获取部门列表
     */
    getStationList(_, { page, size, keywords }) {
        return axios.get("/web/department/list", {
            params: {
                label: 3,
                page,
                size,
                keywords
            },
        });
    },

    /**
     * 添加部门
     */
    addStation(_, { name }) {
        return axios.post(
            "/web/department/add",
            {
                name,
                label: 3,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 编辑部门
     */
    editStation(_, { name, id }) {
        return axios.post(
            "/web/department/edit",
            {
                id,
                name,
                label: 3,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
    },

    /**
     * 删除部门/职务/岗位
     */
    delDepartment(_, { id }) {
        return axios.get("/web/department/del", {
            params: {
                id,
            },
        });
    },

    /**
     * 添加/删除角色账号权限
     */
    auth_manage(_, {
        header,
        first_nav,
        second_nav,
        role_id,
        id
    }) {
        return axios.post("/web/company/setAccess", {
            header,
            first_nav,
            second_nav,
            role_id,
            id
        })
    },

    get_authlist(_, { role_id }) {
        return axios.get("/web/company/accessInfo", {
            params: {
                role_id
            }
        })
    },




    /**
     * 添加/编辑权限角色,获取角色列表
     */
    role_auth(_, { id, name }) {
        return axios.post("/web/company/saveRole", {
            id,
            name
        })
    },

    del_role(_, { id }) {
        return axios.post("/web/company/delRole", {
            id
        })
    },

    get_rolelist(_, { page, uid, keywords }) {
        return axios.get("/web/company/getRoleList", {
            params: {
                page,
                limit: 15,
                keywords,
                uid
            }
        })
    },
    //   劳务公司部分
    // 劳务公司列表
    LabourList(_, params) {
        return axios.get("/web/companySetting/getLabourList", {
            params
        })
    },
    // 添加劳务公司
    add_labour_company(_, params) {
        return axios.post("/web/companySetting/addLabour", params)
    },
    // 编辑劳务公司
    edit_labour_company(_, params) {
        return axios.post("/web/companySetting/editLabour", params)
    },
    // 编辑劳务公司状态
    edit_labour_status(_, params) {
        return axios.post("/web/companySetting/editLabourStatus", params)
    },
    // 删除劳务公司
    delete_labour(_, params) {
        return axios.get("/web/companySetting/delLabourDetail", { params })
    },
    // 劳务公司详情
    get_labour_detail(_, params) {
        return axios.get("/web/companySetting/getLabourDetail", { params })
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
